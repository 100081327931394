import React, {Component} from 'react';
import { Link, Redirect } from "react-router-dom";
import Apis from '../Apis';
import AlbumArtisList from './../albums/AlbumArtistList';
import {month,shortMonth} from '../../constants/TimeStamp';
import {shuffle} from '../ApiCall';

class Albums extends Component{
      constructor(props) {
        super(props);
        this.state={
            albumImageShruffle:[],
            getAlbums:[],
            AlbumsLength:0,
            showAlbumsStatus:false,
            Albumid:'',
        } 
    console.log(props)

  }

  componentWillMount(){
    fetch(Apis.AlbumsApi.url, {
        method:Apis.AlbumsApi.method
        }).then(results => {
            return results.json()
        }).then(getAlbums => {
            console.log(getAlbums)
            this.setState({ getAlbums: getAlbums.data, AlbumsLength: getAlbums.data.length });
           // this.newAlbumRandomImage(getAlbums.data);
            this.AlbumsFilter();
        });
  }

  

  newAlbumRandomImage = (getAlbumsImage) => {
        var albumImageArray = [];
        var getalbumRandomImage = getAlbumsImage.filter(function(value, index, array) {
            albumImageArray.push(value.image);
        })
        shuffle(albumImageArray);
        this.setState({ albumImageShruffle: shuffle(albumImageArray) });
        console.log(this.state.albumImageShruffle);
    }

  AlbumsFilter = (currentMonth = new Date().getMonth() + 1, currentYear = new Date().getFullYear()) => {
        var AlbumData = this.state.getAlbums;
        var selectedDate = month[currentMonth] + currentYear;
        if (AlbumData.length) {
            var renderAlbums = AlbumData.filter(function(value, index, array) {
                if (value.album_id !== 0 && value.release_date !== null) {
                    var trackDate = month[new Date(value.release_date).getMonth() + 1] + new Date(value.release_date).getFullYear();
                    if (selectedDate === trackDate) {
                        return value;
                    }
                }else{
                    console.log(value);
                }
                return;
            });

            this.setState({ getallAlbums: renderAlbums.length > 0 ? renderAlbums :AlbumData });
            this.newAlbumRandomImage(this.state.getallAlbums);
        } 
    }


    render() {
        
        return(
            <>
                {this.state.getAlbums.length>0 && !this.state.showAlbumsStatus && <div className="container relesegenres">
                    <div className="row">
                        <div className="col-xs-8 text-left">
                             <a href="javascript:void(0)" className="back_icon"><img src={require('../../images/back.png')} alt="" /></a> 
                        </div>
                    </div>
                    <div className="album_image">
                        {this.state.albumImageShruffle && this.state.albumImageShruffle.slice(0, 4).map((albumrandom,index) =>
                            <img src={albumrandom} key={index}alt="" />
                        )}
                    </div>
                    <div className="album_song_detail">
                        <div className="row">
                            <div className="col-xs-9">
                                <h2 className="album_title"></h2>
                                <p><span>{this.state.AlbumsLength.length} Songs</span>.<span>{}</span>.<span>2019</span></p>
                            </div>
                            <div className="col-xs-3 text-right">
                                <a linkto=""><img src={require('../../images/open-shuffel.png')} alt="" /> </a>
                            </div>
                        </div>
                    </div> 
                    {this.state.getAlbums && this.state.getAlbums.map((albumDetails )=>
                        <div className="allnewReleaseInfo" key={albumDetails.id} onClick={(e)=>this.setState({showAlbumsStatus:true,Albumid:albumDetails})}>
                            <div className="song-list row">
                                <div className="col-xs-12" onClick={(e)=> this.setState({geturis:"spotify:album:"+albumDetails.spotify_id})}>
                                    <div className=" row_inner text-left">
                                        <h4 className="album-name">{albumDetails.name}</h4>
                                        <div className="artist-name">{albumDetails.artist !== null  ? albumDetails.artist.name:"" }</div>
                                        <div className="sportify-id">{albumDetails.spotify_id}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        )
                    }
                </div>}

                <>
                    {this.state.showAlbumsStatus && <AlbumArtisList getArtistdata={this.state.Albumid}/>}
                </>
            }
            </>
        )
    }
}

export default Albums;