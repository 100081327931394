import React from 'react'
export const play=(handleCallback)=>{
	return (<><button onClick={handleCallback}><img src={require('../../images/pause-icon.png')} alt=''/></button></>);
}

export const pause =() =>{
	return (<><button><img src={require('../../images/play-icon.png')} alt=''/></button></>);
}

export  const seekbar=()=>{
	return(<><div className="player-progress-main">
            <span className="player-id">ID:  </span>
            <span className="player-progress"></span>
            <span className="player-duration"> Duration: </span>
          </div></>);
}

export const repeatButton=()=>{
	return(<><button className="repeat-icon"><img src={require('../../images/repeat-icon.png')} alt=''/></button></>);
}

export const shuffleButton=()=>{
	return(<><button className="shuffle-icon"><img src={require('../../images/shuffle-icon.png')} alt=''/></button> </>);
}

export const nextTrack=()=>{
	return(<><button><img src={require('../../images/next-icon.png')} alt=''/></button></>);
}

export const prevTrack=()=>{
	return(<><button><img src={require('../../images/prev-icon.png')} alt=''/></button></>)
}

