import React, { Component } from 'react';
import {Link,Redirect} from 'react-router-dom';
import {shuffle} from '../ApiCall';
import Apis from '../Apis';
import {month,shortMonth} from '../../constants/TimeStamp';


class NewSingles extends Component{
  constructor(props){
    super(props);
    this.state={
      SinglesImageShruffle: [],
      getNewSingles: [],
    }
  }

  componentDidMount(){
    fetch(Apis.newSinglesApi.url, {
            method: Apis.newSinglesApi.method
        }).then(results => {
            return results.json()
        }).then(resSingles => {
            console.log(resSingles,"######");
            this.setState({ getNewSingles: resSingles.data });
            //this.NewSinglesFilter();
            this.newSinglesRandomImage(this.state.getNewSingles);
        });
  }

    newSinglesRandomImage = (getNewSinglesall) => {
        var SinglesImageArray = [];
        var getsinglesRandomImage = getNewSinglesall.filter(function(value, index, array) {
            SinglesImageArray.push(value.image);
        })
        shuffle(SinglesImageArray);
        this.setState({ SinglesImageShruffle: shuffle(SinglesImageArray) });
    }

    NewSinglesFilter = (currentMonth = new Date().getMonth() + 1, currentYear = new Date().getFullYear()) => {
        var NewSinglesData =this.state.getNewSingles;
        var selectedDate = month[currentMonth] + currentYear;
        if (NewSinglesData.length) {
            var renderNewSingles = NewSinglesData.filter(function(value, index, array) {
                if (value.album_id !== 0 && value.release_date !== null) {
                    var trackDate = month[new Date(value.release_date).getMonth() + 1] + new Date(value.release_date).getFullYear();
                    if (selectedDate === trackDate) {
                        return value;
                    }
                }
                return;
            });

            this.setState({ getNewSingles: renderNewSingles });
            this.newSinglesRandomImage(renderNewSingles);
        } else {
            this.setState({ getNewSingles: NewSinglesData });
        }
    }


  

  render(){
    return(
      <div className="container relesegenres">
                        <div className="row">
                            <div className="col-xs-8 text-left">
                               <a href="javascript:void(0)" className="back_icon"><img src={require('../../images/back.png')} alt="" /></a>
                            </div>
                        </div>
                        <div className="album_image">
                            {this.state.SinglesImageShruffle.slice(0, 4).map((singlesrandom,index) =>
                                <img src={singlesrandom} key={index} alt="" />
                            )}
                        </div>
                        <div className="album_song_detail">
                            <div className="row">
                                <div className="col-xs-9">
                                    <h2 className="album_title"></h2>
                                    <p><span>{this.state.getNewSingles.length} Songs</span>.<span>{}</span>.<span>2019</span></p>
                                </div>
                                <div className="col-xs-3 text-right">
                                    <a linkto=""><img src={require('../../images/open-shuffel.png')} alt="" /> </a>
                                </div>
                            </div>
                        </div>
                        {this.state.getNewSingles.map((singlesDetails,index) =>
                            <div className="allnewReleaseInfo">
                                <div className="song-list row" key={index}>
                                    <div className="col-xs-12">
                                        <div className=" row_inner text-left">
                                            <h4>{singlesDetails.name}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div> );
  }

}

export default NewSingles;