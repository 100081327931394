import React, { useCallback, useRef, useState,useImperativeHandle, forwardRef  } from 'react';
import { hot } from 'react-hot-loader';
import SpotifyWebPlayer, { STATUS } from 'react-spotify-web-playback';
import { ICallbackState as RSWPCallback } from 'react-spotify-web-playback/lib/types/common';
import {play,seekbar,repeatButton,nextTrack,prevTrack,shuffleButton} from './PlayerConst';
import {
  Button,
  Disclaimer,
  Form,
  Heading,
  Input,
  List,
  Player,
  ScopeTitle,
  Selector,
} from './component';

const validateURI = (input: string): boolean => {
  let isValid = false;

  if (input && input.indexOf(':') > -1) {
    const [key, type, id] = input.split(':');

    if (key && type && type !== 'user' && id && id.length === 22) {
      isValid = true;
    }
  }

  return isValid;
};

const parseURIs = (input: string): string[] => {
  const ids = input.split(',');

  return ids.every(d => validateURI(d)) ? ids : [];
};

const InitPlayer = () => {
  const scopes = [
    'streaming',
    'user-read-birthdate',
    'user-read-email',
    'user-read-private',
    'user-library-read',
    'user-library-modify',
    'user-read-playback-state',
    'user-modify-playback-state',
  ];
  const savedToken = localStorage.getItem('spotify_access_token');
  const URIsInput = useRef<HTMLInputElement>(null);
  const [token, setToken] = useState(savedToken || '');
  const [isPlaying, setIsPlaying] = useState(false);
  const [URIs, setURIs] = useState(['spotify:album:7KvKuWUxxNPEU80c4i5AQk']);
  const [validURI, setValidURI] = useState(false);

  const handleSubmit = useCallback(e => {
    e.preventDefault();

    const token = e.target.elements[0].value;

    if (token) {
      setToken(token);
      localStorage.setItem('spotify_access_token', token);
      e.target.reset();
    }
  }, []);

  const handleSubmitURIs = useCallback(e => {
    e.preventDefault();

    if (URIsInput && URIsInput.current) {
      setURIs(parseURIs(URIsInput.current.value));
    }
  }, []);

  const handleChangeURIs = useCallback(e => {
    e.preventDefault();

    if (URIsInput && URIsInput.current) {
      setValidURI(!!parseURIs(URIsInput.current.value).length);
    }
  }, []);

  const handleClickURIs = useCallback(e => {
    e.preventDefault();
    const { uris } = e.currentTarget.dataset;

    setURIs(parseURIs(uris));
    setIsPlaying(true);

    if (URIsInput && URIsInput.current) {
      URIsInput.current.value = uris;
    }
  }, []);

  const handleCallback = useCallback(({ type, ...state }: RSWPCallback) => {
    alert();
    console.group(`RSWP: ${type}`);
    console.log(state);
    console.groupEnd();

    setIsPlaying(state.isPlaying);

    if (state.status === STATUS.ERROR && state.errorType === 'authentication_error') {
      localStorage.removeItem('spotify_access_token');
      setToken('');
    }
  }, []);



  return (
    <div>
      <div className="player-main">
      <div className="panel panel-default">
        <div className="panel-heading">Now Playing View</div>
        <div className="panel-body">          
          <div className="player-progress-wrap">
          {repeatButton()}
          {seekbar()}
          {shuffleButton()} 
          </div>
          <div className="player-btn-sec">
          {prevTrack()}
          {play(handleCallback)}
          {nextTrack()}
          </div>
        </div>
      </div>
      </div>

    </div>
  );
}

export default process.env.NODE_ENV === 'development' ? hot(module)(InitPlayer) : InitPlayer;
