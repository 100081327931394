import React, {Component} from 'react';
import { Link, Redirect } from "react-router-dom";
import Apis from '../Apis';
import {titleSliderSettings} from '../../constants/sliderSetting';
import {month,shortMonth} from '../../constants/TimeStamp';
import Slider from "react-slick";

class NewReleasesMidSection extends Component{
	constructor(props){
		super(props);
		this.state={
			popularGenresRelease: [],
		}
		console.log(this.props.onChange);
	}

	componentDidMount(){
		fetch(Apis.popularGenres.url, {
            method: Apis.popularGenres.method
        }).then(results => {
            return results.json()
        }).then(response => {
            this.setState({ popularGenresRelease: response });
            this.popularGenresFilter();
        });
	}

 	popularGenresFilter = (currentMonth = new Date().getMonth() + 1, currentYear = new Date().getFullYear()) => {
        var popularGenres = this.state.popularGenresRelease;
        var selectedDate = this.props.onChange===null ? month[currentMonth] + currentYear : this.props.onChange;
        console.log(selectedDate);
        var self = this;
        ////console.log("newReleasebefore: ", popularGenresrelease.length);
        if (popularGenres.length) {
            var renderPopularGenres = popularGenres.filter(function(value, index, array) {
                ////console.log(value);
                if (value.created_at !== null) {
                    var trackDate = month[new Date(value.created_at).getMonth() + 1] + new Date(value.created_at).getFullYear();
                    if (selectedDate === trackDate) {
                        return value;
                    }
                }
                return;
            });
            self.setState({ popularGenresRelease: renderPopularGenres });
        } else {
            self.setState({ popularGenresRelease: popularGenres });
        }
    }

	render(){	
		return(
			<div className='NewReleasesMidSection'>
			<div className="newrelease">
		                  <div className="container">
		                    <div className="row">
		                      <div className="col-xs-9">
		                        <img src={require("../../images/newrelease.png")} alt="" /><h4>New Releases by Genre</h4>
		                      </div>
		                      <div className="col-xs-3 text-right">
		                        <Link to="./seeAll" > SEE ALL </Link>
		                      </div>
		                    </div>
		                  </div>
		                </div>

		             			 <div className="title_carousel">
		                  <div className="container">
		                    <div className="row">
		                      {this.state.popularGenresRelease.length > 2 ?
		                        <Slider {...titleSliderSettings} className="title_slider">
		                          {this.state.popularGenresRelease.length > 5 ? this.state.popularGenresRelease.slice(0, 5).map((popularGenresdata,index) =>
		                            <div key={index}>
		                              <div className="item text-center" onClick={((e) => this.showAllNewReleaseGenresRefrencedTracks(e, popularGenresdata))}>
		                                <h3>{popularGenresdata.name}</h3>
		                              </div>

		                            </div>
		                          ) : this.state.popularGenresRelease.map((popularGenresdata,index) =>
		                            <div key={index}>
		                              <div className="item text-center" onClick={((e) => this.showAllNewReleaseGenresRefrencedTracks(e, popularGenresdata))}>
		                                <h3>{popularGenresdata.name}</h3>
		                              </div>
		                            </div>
		                          )}

		                        </Slider> : <div className="title_slider">{this.state.popularGenresRelease.map((popularGenresdata,index) =>
		                          <div key={index}>
		                            <div className="item text-center" onClick={((e) => this.showAllNewReleaseGenresRefrencedTracks(e, popularGenresdata))}><h3>{popularGenresdata.name}</h3>
		                            </div>
		                          </div>
		                        )}
		                        </div>
		                      }
		                    </div>
		                  </div>
		                </div>
		               </div>
		               )
    }                    
}

export default NewReleasesMidSection;