import React, {Component} from 'react';
import {BrowserRouter,Route,browserHistory,Link} from 'react-router-dom';
//import Modal from 'react-modal';
import searchIcon from "../../images/search-icon.png";
import back from "../../images/back.png";
import  rightArrow from "../../images/right-arrow.png";
import { Footer } from "../Footer";
import upload from "../../images/upload.png";
import albumsetting from "../../images/albumsetting.png";
import songthumb from "../../images/songthumb.png";
import heart from "../../images/heart.png";
import dots from "../../images/dots.png";
import favourite from "../../images/favourite.png";
import songThrumb from "../../images/song-thumb.png";
import startcricle from "../../images/start_cricle.png";
import recentclose from "../../images/recent-close.png";
import menuIcon from '../../images/home-menu-icon.png';
import {Header} from "../Header";





var API = "https://staging.injn.io/secure/tracks/top";
export class Search extends Component{ 
    constructor(props) {
        super(props);
        this.state={
            allGenres: [],
            isLoaded: false,
            isDataLoaded:false,
            searchfield: '',

        }
    }

    componentDidMount(){
        fetch(API, { method: "GET" }).then(results => {
            return results.json();
        }).then(data => this.setState({ isLoaded: true, allGenres: data }));
        }

    onSearchSongs(e, searchfield) {
        if (e.target.value == "") {
            this.setState({ searchfield: e.target.value, isDataLoaded: false })
        }
        else { this.setState({ searchfield: e.target.value, isDataLoaded: true })}
     }   

    GetAlbumTitle(e, data) {
        let isLoaded = true;
            let albumtitle = this.state.popularGenres;
            albumtitle.map((currentItem) => {
                return (<div key={currentItem.id}><div className="data-tile">{currentItem.name}<img src={currentItem.image} alt="" /></div></div>)
            })
        this.setState({ isLoaded: false, albumtitle: albumtitle, songId: data})
    }

  
        
    render() {    
        var { isLoaded ,allGenres, searchfield,isDataLoaded} = this.state;   
        const filterCharacters = allGenres.filter(SongsFiltered => {
             return SongsFiltered.name.indexOf(searchfield) > -1
        })
        return (

            <div className="search_page noshade">  
                     
                     <div className="cooming_soon">
                        <h3>Coming soon</h3>
                        <button>View Roadmap</button>
                     </div>
                {/* <div className="container">
                    <div className="row search_header">
                        <div className="col-xs-2 text-left">
                            <Header/>
                        </div>

                        <div className="searchbar col-xs-10">
                                 <input className="search_input" type="text" name="" placeholder="Search..." onChange = {((e) => this.onSearchSongs(e,searchfield))} />
                                <a  href="javascript:void(0)" className="search_icon"></a>
                            </div>
                         
                    </div>
                    {isDataLoaded ? 
                    <div className="search-results">
                            {filterCharacters.map(filteredresults =>
                                <div key={filteredresults.id}>
                                     <div className="col-xs-12 genres" >
                                         <div className="gen_row">
                                            <span className="col-xs-10">{filteredresults.name}</span>
                                            <a className="col-xs-2 text-right" href="javascript:void(0)" onClick={((e) => this.GetAlbumTitle(e, filteredresults.id))}><img src={rightArrow} alt="" /></a>
                                        </div>
                                    </div>      
                                </div>
                            )} 
                    </div>
                    : " "}
                    <div className="populer_search">
                        <div className="row">
                                <h3 className="col-xs-12 text-center">Most Searched</h3>
                        </div>
                        <div className="search_list">
                                <span className="serach_name size-xxxl">Absolute Pop</span>
                                <span className="serach_name size-s">Drink</span>
                                <span className="serach_name size-xs">Rap</span>
                                <span className="serach_name size-l">Autumn</span>
                                <span className="serach_name size-xxl">Training</span>
                                <span className="serach_name size-xl">Success</span>
                                <span className="serach_name size-m">Travel</span>
                                <span className="serach_name size-x">Hits</span> 
                        </div>
                        <div className="recent_search">
                             <h3 className="col-xs-12 text-center">Recent Searches</h3>
                        </div>
                            <div className="search_list_inner">
                            <div className="row">
                                <div className="col-xs-9">
                                    <h3 className="recent_search_name">Text Area</h3>
                                    <span>99h ago</span>
                                </div>
                                <div className="col-xs-3 text-right">
                                    <a href=""><img src={recentclose} alt="" /> </a>
                                </div>
                                </div>
                            </div>
                            <div className="search_list_inner">
                            <div className="row">

                                <div className="col-xs-9">
                                    <h3 className="recent_search_name">Text Area</h3>
                                    <span>99h ago</span>
                                </div>
                                <div className="col-xs-3 text-right">
                                    <a href=""><img src={recentclose} alt="" /> </a>
                                </div>
                                </div>
                            </div>
                            <div className="search_list_inner">
                            <div className="row">

                                <div className="col-xs-9">
                                    <h3 className="recent_search_name">Text Area</h3>
                                    <span>99h ago</span>
                                </div>
                                <div className="col-xs-3 text-right">
                                    <a href=""><img src={recentclose} alt="" /> </a>
                                </div>
                                </div>
                            </div>
                            <div className="search_list_inner">
                            <div className="row">

                                <div className="col-xs-9">
                                    <h3 className="recent_search_name">Text Area</h3>
                                    <span>99h ago</span>
                                </div>
                                <div className="col-xs-3 text-right">
                                    <a href=""><img src={recentclose} alt="" /> </a>
                                </div>
                                </div>
                            </div>
                    </div>    
                </div> */}
                <Footer />
            </div>
        );
    }
}