import React, { Component } from 'react';
import {Link,Redirect} from 'react-router-dom';
import {shuffle} from '../ApiCall';
import Apis from '../Apis';
import {month,shortMonth} from '../../constants/TimeStamp';
import {player} from '../spotifyPlayer/Apicalls';
import TestSpotify from '../spotifyPlayer/TestSpotify';

import Slider from "react-slick";
let songsurl=[];
var AdvertiseSlider = {
        responsive: [{
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    dots: false,
                    arrows: false,
                    infinite: true,

                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: false,
                    variableWidth: 200,
                    dots: false,
                    arrows: false,
                }
            },
        ]
};
class TrendingTracks extends Component{
  constructor(props){
    super(props);
    console.log(props.onChange);
    this.state={
      trendingTracks: [],
      trendingItems: [],
      openpopup:true,
      dateChange:props.onChange,
      getTracks:null,
      device_id:localStorage.getItem('_spharmony_device_id'),
    }
    this.checkPlayerStatus=this.checkPlayerStatus.bind(this);

  }


  componentDidMount(){
    console.log(this.state.dateChange);
    if(this.state.trendingTracks<=0){
  	fetch(Apis.TrendingTacksAPI.url, {
            method: Apis.TrendingTacksAPI.method
        }).then(results => {
            return results.json();
        }).then(resData => {
            console.log(resData)
            this.setState({ trendingTracks: resData })
            this.trendingByFilter();
        });
      }else{
        this.trendingByFilter();
      }
  }

  trendingByFilter = (currentMonth = new Date().getMonth()+1, currentYear = new Date().getFullYear()) => {
        var trendingData = this.state.trendingTracks;
        var getTracksdata;
        var changeddate=this.state.dateChange===null ? month[currentMonth] + currentYear : this.state.dateChange;
        console.log(changeddate);
            if (trendingData.length) {
                var renderTrending = trendingData.filter(function(value, index, array) {
                    if (value.album_id !== 0 && value.album.release_date !== null) {
                        var trackDate = shortMonth[new Date(value.album.release_date).getMonth()] + new Date(value.album.release_date).getFullYear();
                        if (changeddate===trackDate ) {
                            return value;
                        }
                    }
                    return;
                });
                let getTracksdata=renderTrending.map((items,index)=>{
                              songsurl.push(items.url)
                              return(<div className="trending-items" key={index} onClick={this.checkPlayerStatus}>
                              <div className="song-list row">
                                <div className="col-xs-2" >
                                  <img src={items.album.image} alt=""  />
                                </div>
                                <div className="col-xs-10" >
                                  <div className="row_inner" >
                                    <div className="col-xs-10 text-left" >
                                      <h4 >{items.name}</h4>
                                      <span className="artist-name" >{items.album.artist.name}</span>
                                    </div>
                                    <div className="col-xs-2 ">
                                      <img className="playing-icon" src={require('../../images/playing.png')} alt="" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>)
                           })

                this.setState({getTracks:getTracksdata})
            } else {
                console.log("else");
                this.setState({ trendingItems: trendingData });
            }
    }

    checkPlayerStatus=()=>{
        setTimeout(()=>{
            player(this.state.device_id,songsurl,localStorage.getItem('spotify_access_token')) ;
        },2000)
    }

    openLogin=()=>{
    	this.props.loginpopup(this.state.openpopup);
    }

  render(){
  	return( 
  		      <div className = "tranding container" >
                <div className="row newrelease_title">
                    <div className="col-xs-9">
                      <img src={require('../../images/star.png')} alt="" /><h4>Trending</h4>
                    </div>
                    <div className="col-xs-3 text-right">
                      <Link to="./home-content/seeAll" > SEE ALL </Link>
                    </div>
                  </div> 
               <div className = "before-adve" > 
                {this.state.getTracks}
               </div>
               <Slider { ...AdvertiseSlider } className = "newrelease_slider row">
                	<div className="item text-center" onClick={(e)=>this.openLogin(e)} >
                      <h3>Help make INJN better</h3>
                      <p>Give feedback or suggest an idea!</p>
                    </div> 
           			<div className = "item text-center" >
                		<h3>Work with us</h3> 
                		<p> Help us categorise all songs by genre </p> 
               		</div> 
               </Slider> 
            </div>
  		)
  }
}

export default TrendingTracks;