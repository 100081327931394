import React, {Component} from 'react';
import {Header} from "./Header";
import {Footer} from "./Footer";
export const Aboutus=(props)=>{ 
        return (
        <div className="Aboutus shade">
        <header className="Aboutus-header white">
           <Header/>
        </header>
        < section className = "banner_section" >
                 <div className = "container" >
                 <div className = "row" >
                 <img src = {
                     require('../images/22.png')
                 }
             alt = ""/>
                 <h3 className = "page_title" > About us </h3> </div>     </div>       
        </section>

        < section className = "inner_page_main" >
            <div className = "container" >
            <h4 className = "hedding" > OUR MISSION </h4> <p> Hazey Announced her new single called“ Gold Happines” that will be released this 22 November with a < a href = "" > Music Video </a>.</p>

            <p > Phasellus congue finibus mi et imperdiet desi, Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. </p>

            <p > Curabitur sed nibh sed leo auctor ornare.Nullam a sem finibus, tempus leo et, sagittis nunc.Sed egestas ante nec nibh vestibulum, ut convallis libero hendrerit.Donec sit amet dui ut sem maximus tempus laoreet finibus dolor.Donec semper at felis sed luctus.Sed fermentum placerat ornare.Nunc euismod libero eget urna convallis tristique. </p> 
            <div className = "row">
            <figure >
            <img src = {require('../images/mise-en-relation-expert-comptable.png')}
        alt = ""/>
            </figure> </div> <p > Hazey Announced her new single called“ Gold Happines” that will be released this 22 November with a Music Video. </p>

            <p > Phasellus congue finibus mi et imperdiet desi, Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. </p>

            <p > Curabitur sed nibh sed leo auctor ornare.Nullam a sem finibus, tempus leo et, sagittis nunc.Sed egestas ante nec nibh vestibulum, ut convallis libero hendrerit.Donec sit amet dui ut sem maximus tempus laoreet finibus dolor.Donec semper at felis sed luctus.Sed fermentum placerat ornare.Nunc euismod libero eget urna convallis tristique. </p>

            <div className = "play_video" >
            <iframe src ="https://www.youtube.com/embed/NsQ3pgRharM"></iframe> </div> <p > Hazey Announced her new single called“ Gold Happines” that will be released this 22 November with a Music Video. </p>

            <p > Phasellus congue finibus mi et imperdiet desi, Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. </p>

            <p > Curabitur sed nibh sed leo auctor ornare.Nullam a sem finibus, tempus leo et, sagittis nunc.Sed egestas ante nec nibh vestibulum, ut convallis libero hendrerit.Donec sit amet dui ut sem maximus tempus laoreet finibus dolor.Donec semper at felis sed luctus.Sed fermentum placerat ornare.Nunc euismod libero eget urna convallis tristique. </p>

            <a className = "tellus" href = "#" > Tell us what you think! </a> </div> 
        </section>
       <Footer/>
</div>
);
}
