import React, { Component } from 'react';
import {  nextTrack,prevTrack,pauseTrack,playTrack,seekPosition,repeatTrack,repeatContext,repeatOff,shuffleTrue} from './Apicalls';

 class NowPlaying extends Component {
  render() {
  if(this.props.playerState!==null){
    let {
      playerState,
      playerState: { position: position_ms }
    } = this.props;
    let {
      id,
      uri: track_uri,
      name: track_name,
      duration_ms,
      artists: [{
        name: artist_name,
        uri: artist_uri
      }],
      album: {
        name: album_name,
        uri: album_uri,
        images: [{ url: album_image }]
      }
    } = playerState.track_window.current_track;
    let timeCollapse = position_ms/duration_ms*100;
    return (
      <div className="player-main">
      <div className="panel panel-default">
        <div className="panel-heading">Now Playing View</div>
        <div className="panel-body">
          {window.location.pathname==='/Home' ? '' :<img src={album_image} alt={track_name} />}
          
          <h4><a href={track_uri}>{track_name}</a> {/*by <a href={artist_uri}>{artist_name}</a> */}</h4>
          <h6><a href={album_uri}>{album_name}</a></h6>
          <div className="player-progress-wrap">
          <button className="repeat-icon" onClick={(e)=>repeatTrack(localStorage.getItem('_spharmony_device_id'),localStorage.getItem('spotify_access_token'))}><img src={require('../../images/repeat-icon.png')} alt=''/></button>
          <div className="player-progress-main" onClick={(e)=>seekPosition(e,position_ms,duration_ms,localStorage.getItem('spotify_access_token'))}>
            <span className="player-id">ID: {id} </span>
            <span className="player-progress" style={{width:`${timeCollapse}%`}}></span>
            <span className="player-duration"> Duration: {duration_ms}</span>
          </div>
          <button className="shuffle-icon" onClick={(e)=>shuffleTrue(localStorage.getItem('_spharmony_device_id'),localStorage.getItem('spotify_access_token'))}><img src={require('../../images/shuffle-icon.png')} alt=''/></button> 
          </div>
          <div className="player-btn-sec">
          <button onClick={(e)=>prevTrack(localStorage.getItem('spotify_access_token'))}><img src={require('../../images/prev-icon.png')} alt=''/></button>
          {!playerState.paused ? <button onClick={(e)=>pauseTrack(localStorage.getItem('spotify_access_token'))}><img src={require('../../images/pause-icon.png')} alt=''/></button>:
          <button onClick={(e)=>playTrack(localStorage.getItem('spotify_access_token'))}><img src={require('../../images/play-icon.png')} alt=''/></button>}
          <button onClick={(e)=>nextTrack(localStorage.getItem('spotify_access_token'))}><img src={require('../../images/next-icon.png')} alt=''/></button>
          </div>
        </div>
      </div>
      </div>
    );
  }else{
    return(
    <div className="player-main">
      <div className="panel panel-default">
        <div className="panel-heading">Now Playing View</div>
        <div className="panel-body">          
          <div className="player-progress-wrap">
          <button className="repeat-icon" onClick={(e)=>repeatTrack(localStorage.getItem('_spharmony_device_id'),localStorage.getItem('spotify_access_token'))}><img src={require('../../images/repeat-icon.png')} alt=''/></button>
          <div className="player-progress-main" onClick={(e)=>seekPosition(e,localStorage.getItem('spotify_access_token'))}>
            <span className="player-id">ID: {} </span>
            <span className="player-progress" style={{}}></span>
            <span className="player-duration"> Duration: {}</span>
          </div>
          <button className="shuffle-icon" onClick={(e)=>shuffleTrue(localStorage.getItem('_spharmony_device_id'),localStorage.getItem('spotify_access_token'))}><img src={require('../../images/shuffle-icon.png')} alt=''/></button> 
          </div>
          <div className="player-btn-sec">
          <button onClick={(e)=>prevTrack(localStorage.getItem('spotify_access_token'))}><img src={require('../../images/prev-icon.png')} alt=''/></button>
          <button onClick={(e)=>pauseTrack(localStorage.getItem('spotify_access_token'))}><img src={require('../../images/pause-icon.png')} alt=''/></button>
          <button onClick={(e)=>nextTrack(localStorage.getItem('spotify_access_token'))}><img src={require('../../images/next-icon.png')} alt=''/></button>
          </div>
        </div>
      </div>
      </div>)
    }
  }
}

export default NowPlaying;