  let device_id=localStorage.getItem('_spharmony_device_id'); 

  export function nextTrack(userAccessToken){
    fetch(`https://api.spotify.com/v1/me/player/next`,{
       headers: {
                "Authorization": `Bearer ${userAccessToken}`,
                "Content-Type" : "application/json",
                },
      method:'POST',
    })
  }; 

  export function prevTrack(userAccessToken){
    fetch(`https://api.spotify.com/v1/me/player/previous`,{
       headers: {
                "Authorization": `Bearer ${userAccessToken}`,
                "Content-Type" : "application/json",
                },
      method:'POST',
    })
  };

  export function pauseTrack(userAccessToken){
    try{
    fetch(`https://api.spotify.com/v1/me/player/pause`,{
       headers: {
                "Authorization": `Bearer ${userAccessToken}`,
                "Content-Type" : "application/json",
                },
      method:'PUT',
    })

    }catch(error){
      console.log(error)
    }
  };

  export function playTrack(userAccessToken){
    try{
    fetch(`https://api.spotify.com/v1/me/player/play`,{
       headers: {
                "Authorization": `Bearer ${userAccessToken}`,
                "Content-Type" : "application/json",
                },
      method:'PUT',
    })

    }catch(error){
      console.log(error)
    }
  };

  export function changeTrack(device_id,songsurl,user_access_token){
    console.log(device_id);
    console.log(songsurl);
    console.log(user_access_token);
    let options={
      uris:songsurl,
       offset:{
             position:0
          }, 
    }
    try{
    fetch(`https://api.spotify.com/v1/me/player/play?device_id=${device_id}`,{
       headers: {
                "Authorization": `Bearer ${user_access_token}`,
                "Content-Type" : "application/json",
                },
        body:JSON.stringify(options),
        method:'PUT',
    })

    }catch(error){
      console.log(error)
    }
  };

  export function player(device_id,songsurl,user_access_token){
    try{
      setTimeout(()=>{
        changeTrack(device_id,songsurl,user_access_token);
      },2000);
    }catch(error){
      console.log(error);
    }
  }

  export function seekPosition(e,position_ms,duration,userAccessToken){
    var offset=e.target.offsetLeft;
    var left = (e.pageX - offset);
    console.log(left)

    var totalWidth = document.querySelector('.player-progress-main').clientWidth;
    console.log(totalWidth);
    var percentage = ( left / totalWidth );
    console.log(percentage);
    var vidTime = Math.trunc(duration * percentage);
    console.log(vidTime);
    try{
      fetch(`https://api.spotify.com/v1/me/player/seek?position_ms=${vidTime}`,{
       headers: {
                "Authorization": `Bearer ${userAccessToken}`,
                "Content-Type" : "application/json",
                },
      method:'PUT',
    })
    }catch(error){

    }
  };

  export function getCurrentTrack(userAccessToken){
    try{
       fetch(`https://api.spotify.com/v1/me/player`,{
        headers:{
          "Authorization":`Bearer ${userAccessToken}`,
          "Content-Type" : "application/json",
        },
        method:'GET'
      })
    }catch(error){
      console.log(error);
    }
  }

  export function repeatTrack(device_id,userAccessToken){
    try{
        fetch(`https://api.spotify.com/v1/me/player/repeat/?state=track&device_id=${device_id}`,{
            headers: {
              "Authorization": `Bearer ${userAccessToken}`,
              "Content-Type" : "application/json",
              },
            method:'PUT',
        });
    }catch(error){
      console.log(error)
    }
  }

  export function repeatOff(device_id,userAccessToken){
    try{
        fetch(`https://api.spotify.com/v1/me/player/repeat/?state=off&device_id=${device_id}`,{
            headers: {
              "Authorization": `Bearer ${userAccessToken}`,
              "Content-Type" : "application/json",
              },
            method:'PUT',
        });
    }catch(error){
      console.log(error)
    }
  }

 export function repeatContext(device_id,userAccessToken){
    try{
          fetch(`https://api.spotify.com/v1/me/player/repeat/?state=context&device_id=${device_id}`,{
            headers: {
              "Authorization": `Bearer ${userAccessToken}`,
              "Content-Type" : "application/json",
              },
            method:'PUT',
          });
    }catch(error){
      console.log(error)
    }
  }

  export function shuffleTrue(device_id,userAccessToken){
    try{
        fetch(`https://api.spotify.com/v1/me/player/shuffle/?state=true&device_id=${device_id}`,{
            headers: {
              "Authorization": `Bearer ${userAccessToken}`,
              "Content-Type" : "application/json",
              },
            method:'PUT',
        })
    }catch(error){
      console.log(error)
    }
  }
  
  export function shuffleFalse(device_id,userAccessToken){
    try{
        fetch(`https://api.spotify.com/v1/me/player/shuffle/?state=false&device_id=${device_id}`,{
            headers: {
              "Authorization": `Bearer ${userAccessToken}`,
              "Content-Type" : "application/json",
              },
            method:'PUT',
        })
    }catch(error){
      console.log(error)
    }
  }
