import React, {Component} from 'react';
import { Link, Redirect } from "react-router-dom";
import {user_access_token} from './../ApiCall';
import TestSpotify from '../spotifyPlayer/TestSpotify';
import {player,pauseTrack} from '../spotifyPlayer/Apicalls';
import {play} from '../spotifyPlayer/PlayerConst';
import renderHTML from 'react-render-html';
let songsurl=[];
class AlbumArtistList extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			getArtistlist:this.props.getArtistdata,
			AlbumsLoaded:true,
			homePageLoad:false,
			isLoaded:true,
            getTacks:null,
            device_id:localStorage.getItem('_spharmony_device_id'),
            playerStatus:false,
		} 
        this.checkPlayerStatus=this.checkPlayerStatus.bind(this);

        console.log(this.state.getArtistlist)
    }
    componentDidMount(){
        this.albumSongs();
    }

    checkPlayerStatus=()=>{
        setTimeout(()=>{
            player(this.state.device_id,songsurl,localStorage.getItem('spotify_access_token')) ;
        },2000)
        this.setState({playerStatus:true});
    }

    albumSongs = () => {
            fetch(`https://api.spotify.com/v1/albums/${this.state.getArtistlist.spotify_id}/tracks`,{
                headers:{
                    "Authorization": `Bearer ${user_access_token}`
                },
                method:'GET'
            })
            .then((res)=>{
                return res.json()
            }).
            then((albumSongs)=>{
                console.log(albumSongs);
                songsurl=[];
                let returnTracks=albumSongs.items.map((tracks,index)=>{         
                    console.log(tracks.uri);
                songsurl.push(tracks.uri);
                return(<div className="allnewReleaseInfo" key={index} onClick={this.checkPlayerStatus}>
                            <div className="song-list row">
                                        <div className="col-xs-12">
                                            <div className=" row_inner text-left">
                                                <h4 className="album-name">{tracks.name}</h4>
                                                <div className="artist-name">{tracks.artists[0].name}</div>
                                            </div>
                                        </div>
                                    </div>
                        </div>);
                })           
                this.setState({getTacks:returnTracks});
            }); 

    }

    render() {
        console.log(this.state.getTacks);
		return (
            <div className="albumArtistPage">
                <div className="container relesegenres">
                        <div className="row">
                            <div className="col-xs-8 text-left">
                                 <Link to="" className="back_icon"><img src={require('../../images/back.png')} alt="" /></Link> 
                            </div>
                        </div>
                        <div className="album_image Genre-image">
                            <img src={this.state.getArtistlist.image} alt="" />
                        </div>

                        <div className="album_song_detail" >
                            <div className="row">
                                <div className="col-xs-12">
                                    <h2 className="album_title">{this.state.getArtistlist.name}</h2>
                                    <div className="album-artist-name">{this.state.getArtistlist.artist.name}</div>
                                </div>
                                {this.state.getTacks}
                            </div>
                        </div>   
                        </div>
                    </div> 
            );
	}
}



export default AlbumArtistList;