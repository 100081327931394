import React, { Component } from 'react';
import {nextTrack,prevTrack,pauseTrack,playTrack,seekPosition,repeatTrack,repeatContext,repeatOff,shuffleTrue,changeTrack} from './spotifyPlayer/Apicalls';
//import {play,seekbar,repeatButton,nextTrack,prevTrack,shuffleButton} from './spotifyPlayer/PlayerConst';

class responsiveMusicPlayer extends Component {
  constructor(props) {
    super(props);
    // set the initial state
    this.state = {
      token: localStorage.getItem("spotify_access_token"),
      deviceId: "",
      loggedIn: true,
      error: "",
      trackName: "Track Name",
      artistName: "Artist Name",
      albumName: "Album Name",
      playing: false,
      position: 0,
      duration: 1,
    };
    // this will later be set by setInterval
    this.playerCheckInterval = null;
      if (this.state.token !== "") {
      // change the loggedIn variable, then start checking for the window.Spotify variable
      this.setState({ loggedIn: true });
      this.playerCheckInterval = setInterval(() => this.checkForPlayer(), 1000);
    }
  }
  
  // when we click the "go" button
  

  
  // when we receive a new update from the player
  onStateChanged(state) {
    // only update if we got a real state
    console.log(state);
    if (state !== null) {
           const {
        current_track: currentTrack,
        position,
        duration,
      } = state.track_window;
      const trackName = currentTrack.name;
      const albumName = currentTrack.album.name;
      const artistName = currentTrack.artists
        .map(artist => artist.name)
        .join(", ");
      const playing = !state.paused;
      this.setState({
        position,
        duration,
        trackName,
        albumName,
        artistName,
        playing
      });
    } else {
      // state was null, user might have swapped to another device
      this.setState({ error: "Looks like you might have swapped to another device?" });
    }
  }
  
  createEventHandlers() {
    // problem setting up the player
    this.player.on('initialization_error', e => { console.error(e); });
    // problem authenticating the user.
    // either the token was invalid in the first place,
    // or it expired (it lasts one hour)
    this.player.on('authentication_error', e => {
      console.error(e);
      this.setState({ loggedIn: false });
    });
    // currently only premium accounts can use the API
    this.player.on('account_error', e => { console.error(e); });
    // loading/playing the track failed for some reason
    this.player.on('playback_error', e => { console.error(e); });
  
    // Playback status updates
    this.player.on('player_state_changed', state => this.onStateChanged(state));
    // Ready
    this.player.on('ready', async data => {
      let { device_id } = data;
     

      alert("plkayeing")
      console.log(data);
      console.log("Let the music play on!");
      // set the deviceId variable, then let's try
      // to swap music playback to *our* player!
      await this.setState({ deviceId: device_id });
      this.transferPlaybackHere();
    });
  }
  
  checkForPlayer() {
    const { token } = this.state;
    
    // if the Spotify SDK has loaded
    if (window.Spotify !== null) {
      // cancel the interval
      clearInterval(this.playerCheckInterval);
      // create a new player
      this.player = new window.Spotify.Player({
        name: "Matt's Spotify Player",
        getOAuthToken: cb => { cb(token); },
      });
      // set up the player's event handlers
      this.createEventHandlers();
      let songsurl = 'spotify:track:3jMb51WRDxwfPriUhNwyTm';
      // finally, connect!
      this.player.connect();

     this.state.deviceId && changeTrack(this.state.deviceId,songsurl,localStorage.getItem('spotify_access_token'))

    }
  }
  
  onPrevClick() {
    this.player.previousTrack();
  }
  
  onPlayClick() {  
    console.log(this.player);   
    this.player.togglePlay();
  }
  
  onNextClick() {
    this.player.nextTrack();
  }
  
  transferPlaybackHere() {
    const { deviceId, token } = this.state;
    // https://beta.developer.spotify.com/documentation/web-api/reference/player/transfer-a-users-playback/
    fetch("https://api.spotify.com/v1/me/player", {
      method: "PUT",
      headers: {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        "device_ids": [ deviceId ],
        // true: start playing music if it was paused on the other device
        // false: paused if paused on other device, start playing music otherwise
        "play": true,
      }),
    });
  }
  
  render() {
    const {
      token,
      loggedIn,
      trackName,
      artistName,
      albumName,
      error,
      playing
    } = this.state;
    
    return (
        <div className="player-main" style={{background:'black'}}>
      <div className="panel panel-default">
        <div className="panel-heading">Now Playing View</div>
        <div className="panel-body">          
          <div className="player-progress-wrap">
          <button className="repeat-icon"><img src={require('../images/repeat-icon.png')} alt=''/></button>
          <div className="player-progress-main">
            <span className="player-id">ID: {} </span>
            <span className="player-progress" style={{}}></span>
            <span className="player-duration"> Duration: {}</span>
          </div>
          <button className="shuffle-icon"><img src={require('../images/shuffle-icon.png')} alt=''/></button> 
          </div>
          <div className="player-btn-sec">
          <button ><img src={require('../images/prev-icon.png')} alt=''/></button>
          <button onClick={(e)=>this.onPlayClick()}><img src={require('../images/pause-icon.png')} alt=''/></button>
          <button><img src={require('../images/next-icon.png')} alt=''/></button>
          </div>
        </div>
      </div>
      </div>
    );
  }
}

export default responsiveMusicPlayer;
