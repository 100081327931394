import React, {Component} from 'react';
import {BrowserRouter,Route,browserHistory,Link} from 'react-router-dom';
import searchIcon from "../../images/search-icon.png";
import back from "../../images/back.png";
import  rightArrow from "../../images/right-arrow.png";
import { Footer } from "../Footer";
import {Models} from "../Models";
import upload from "../../images/upload.png";
import albumsetting from "../../images/albumsetting.png";
import songthumb from "../../images/songthumb.png";
import heart from "../../images/heart.png";
import dots from "../../images/dots.png";
import favourite from "../../images/favourite.png";
import songThrumb from "../../images/song-thumb.png";
import startcricle from "../../images/start_cricle.png";
import recentclose from "../../images/recent-close.png";
import menuIcon from '../../images/home-menu-icon.png';
import playlisticon from '../../images/playlist-icon.png';
import addsong from '../../images/addsong.png';
import {Popover,Tooltip,Modal} from 'react-bootstrap';


var playlistApi = 'https://staging.injn.io/secure/playlists';
var API = "https://staging.injn.io/secure/tracks/top";
export class Newplaylist extends Component{ 
    constructor(props) {
        super(props);
        this.createPlayListPopup = this.createPlayListPopup.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.state={
            allGenres: [],
            playlistshow: false,
            isLoaded: false,
            isDataLoaded:false,
            searchfield: '',
            playlistrunning:false,
            getPlaylist: localStorage.getItem("playlist"),
            createNewplaylist: '',
            RunningPlaylistpopup:false,
        }
    }

    componentDidMount(){
        fetch(API, { method: "GET" }).then(results => {
            return results.json();
        }).then(data => this.setState({ isLoaded: true, allGenres: data }));
        }

    onSearchSongs(e, searchfield) {
        if (e.target.value == "") {
            this.setState({ searchfield: e.target.value, isDataLoaded: false })
        }
        else { this.setState({ searchfield: e.target.value, isDataLoaded: true })}
     }   

    GetAlbumTitle(e, data) {
        let isLoaded = true;
            let albumtitle = this.state.popularGenres;
            albumtitle.map((currentItem) => {
                return (<div key={currentItem.id}><div className="data-tile">{currentItem.name}<img src={currentItem.image} alt="" /></div></div>)
            })
        this.setState({ isLoaded: false, albumtitle: albumtitle, songId: data})
    }

    createPlayListPopup() {
         this.setState({playlistshow: true})
    }
   handleClose(){
    this.setState({playlistshow:false,RunningPlaylistpopup:false,playlistrunning:false})
  }

    playListChange(e){
    this.setState({
            playlist: e.target.value
            });
    }

    openRunningPlaylistpopup(e) {
        this.setState({ RunningPlaylistpopup: true });
    }

  createPlaylist(e,data){
    e.preventDefault();
    const newPlaylist={
        playlistname:data
      }
    console.log(newPlaylist);
      const playList = localStorage.setItem("playlist", newPlaylist.playlistname);
      this.setState({ createNewplaylist: playList, playlistshow: false, playlistrunning: true });

  /* return fetch(playlistApi, {
        method: "POST",
        credentials: 'same-origin',
        headers:{
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newPlaylist),
    }).then(res => {
        return res;
        alert(res)
    }).catch(err => err); */
}
  
        
    render() {    
        var { isLoaded ,allGenres, searchfield,isDataLoaded,createNewplaylist,playlistrunning,getPlaylist} = this.state;   
        const filterCharacters = allGenres.filter(SongsFiltered => {
             return SongsFiltered.name.indexOf(searchfield) > -1
        })
        return (

            <div className="Newplaylist noshade">  
                <div className="container">
                    <div className="row Newplaylist_header">
                        <div className="col-xs-12 text-left">
                        <Link to="../Home" className="back_icon"><img src={back} alt=""/></Link>
                        </div>    
                    </div>
                   <div className="add_playalist">
                        <button className="add_playlist_btn" onClick={this.createPlayListPopup}><img src={addsong} alt="" /> NEW PLAYLIST</button>
                   </div>
                   <div className="recent_playlist">
                        <h2>RECENT</h2>
                        <div className="playlist_row">
                            <div className="col-xs-3">
                                <img src={playlisticon} alt="" />
                            </div>
                            <div className="col-xs-9" onClick={((e)=>this.openRunningPlaylistpopup(e))}>
                                 {playlistrunning ? <h3>{createNewplaylist}</h3> : <h3>{getPlaylist}</h3>}
                                <span>463 songs</span>
                            </div>
                        </div>
                   </div>
                   <div className="user_playalist_list">
                        <h2>MY PLAYLISTS</h2>
                        <div className="playlist_row">
                            <div className="col-xs-3">
                                <img src={playlisticon} alt="" />
                            </div>
                            <div className="col-xs-9" onClick={((e)=>this.openRunningPlaylistpopup(e))}>
                                {playlistrunning ? <h3>{createNewplaylist}</h3> : <h3>{getPlaylist}</h3>}
                                <span>463 songs</span>
                            </div>
                        </div>
                        <div className="playlist_row">
                            <div className="col-xs-3">
                                <img src={playlisticon} alt="" />
                            </div>
                            <div className="col-xs-9" onClick={((e)=>this.openRunningPlaylistpopup(e))}>
                                {playlistrunning ? <h3>{createNewplaylist}</h3> : <h3>{getPlaylist}</h3>}
                                <span>463 songs</span>
                            </div>
                        </div>
                        <div className="playlist_row">
                            <div className="col-xs-3">
                                <img src={playlisticon} alt="" />
                            </div>
                            <div className="col-xs-9" onClick={((e)=>this.openRunningPlaylistpopup(e))}>
                                 {playlistrunning ? <h3>{createNewplaylist}</h3> : <h3>{getPlaylist}</h3>}
                                <span>463 songs</span>
                            </div>
                        </div>
                   </div>
                </div>
                 <Modal className="newplaylist_popup" show={this.state.playlistshow} onHide={this.handleClose}>
                        <Modal.Header>
                            <h3>Create New Playlist</h3>
                            <p>Enter a name for this new playlist</p>
                            <input type="text" placeholder="Playlist Name" name="playlist" onChange={((e) => this.playListChange(e, this.state.playlist))} />
                        </Modal.Header>
                        <Modal.Body>
                            <button onClick={((e) => this.handleClose())}>Cancel</button>
                            <button onClick={((e) => this.createPlaylist(e, this.state.playlist))}>Create</button>
                        </Modal.Body>
                </Modal>
               <Modal className="myPlaylist_running" show={this.state.playlistrunning} onHide={this.handleClose}>
                        <Modal.Header>
                        </Modal.Header>
                        <Modal.Body>
                           MY Running Playlist
                        </Modal.Body>
                </Modal> 
               <Modal className="RunningPlaylistpopup" show={this.state.RunningPlaylistpopup} onHide={this.handleClose}>
                        <Modal.Header>
                        </Modal.Header>
                        <Modal.Body>
                           Your Running Playlist
                        </Modal.Body>
                </Modal> 
                <Footer />
            </div>
        );
    }
}