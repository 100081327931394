import React, {Component} from 'react';
import { Link, Redirect } from "react-router-dom";
import Apis from '../Apis';
import {newReleaseSettings} from '../../constants/sliderSetting';
import {month,shortMonth} from '../../constants/TimeStamp';
import Slider from "react-slick";
import {shuffle} from '../ApiCall';

class NewReleasesLastSection extends Component{
	constructor(props){
		super(props);
		this.state={
			newReleseImageShruffle: [],
      newReleaseGenre:[],
			SinglesImageShruffle: [],
      getNewSingles: [],
		  albumImageShruffle:[],
      getAlbums:[],
		}
	}

  componentDidMount(){
    fetch(Apis.apiNewreleases.url, {
            method: Apis.apiNewreleases.method
        }).then(results => {
            return results.json()
        }).then(newReleasedata => {
            console.log(newReleasedata);
            this.setState({ newReleaseGenre: newReleasedata });
            this.newReleaseByFilter();
        });

    fetch(Apis.newSinglesApi.url, {
            method: Apis.newSinglesApi.method
        }).then(results => {
            return results.json()
        }).then(resSingles => {
            var resSinglesData = resSingles.data;
            this.setState({ getNewSingles: resSinglesData });
            this.NewSinglesFilter();
        });

    fetch(Apis.AlbumsApi.url, {
        method:Apis.AlbumsApi.method
        }).then(results => {
            return results.json()
        }).then(getAlbums => {
            console.log(getAlbums)
            this.setState({ getAlbums: getAlbums.data, AlbumsLength: getAlbums.data.length });
           // this.newAlbumRandomImage(getAlbums.data);
            this.AlbumsFilter();
        });    
  }

   newReleaseByFilter = (currentMonth = new Date().getMonth() + 1, currentYear = new Date().getFullYear()) => {
        var newReleaseData = this.state.newReleaseGenre;
        var selectedDate = month[currentMonth] + currentYear;
        /*if (newReleaseData.length) {
            var renderNewRelease = newReleaseData.filter(function(value, index, array) {
                if (value.release_date !== null) {
                    var trackDate = month[new Date(value.release_date).getMonth() + 1] + new Date(value.release_date).getFullYear();
                    if (selectedDate === trackDate) {
                        return value;
                    }
                }
                return;
            });
            this.setState({ newreleases: renderNewRelease });
            this.newReleaseRandomImage(renderNewRelease);
        } else {*/
            this.setState({ newreleases: newReleaseData });
            this.newReleaseRandomImage(newReleaseData);
        //}
    }

     newReleaseRandomImage = (newRelasesRandom) => {
        var releaseImageArray = [];
        var getNewReleaseRandomImage = newRelasesRandom.filter(function(value, index, array) {
            releaseImageArray.push(value.image);
        })
        shuffle(releaseImageArray);
        this.setState({ newReleseImageShruffle: shuffle(releaseImageArray) });
    }

  NewSinglesFilter = (currentMonth = new Date().getMonth() + 1, currentYear = new Date().getFullYear()) => {
        var NewSinglesData = this.state.getNewSingles;
        var selectedDate = this.props.onChange===null ? month[currentMonth] + currentYear : this.props.onChange;
       
        if (NewSinglesData.length) {
            var renderNewSingles = NewSinglesData.filter(function(value, index, array) {
                if (value.album_id !== 0 && value.release_date !== null) {
                    var trackDate = month[new Date(value.release_date).getMonth() + 1] + new Date(value.release_date).getFullYear();
                    if (selectedDate === trackDate) {
                        return value;
                    }
                }
                return;
            });

            this.setState({ getNewSingles: renderNewSingles });
            this.newSinglesRandomImage(renderNewSingles);
        } else {
            this.setState({ getNewSingles: NewSinglesData });
        }
    }


  newSinglesRandomImage = (getNewSinglesall) => {
        var SinglesImageArray = [];
        var getsinglesRandomImage = getNewSinglesall.filter(function(value, index, array) {
            SinglesImageArray.push(value.image);
        })
        shuffle(SinglesImageArray);
        this.setState({ SinglesImageShruffle: shuffle(SinglesImageArray) });
    }

    newAlbumRandomImage = (getAlbumsImage) => {
        var albumImageArray = [];
        var getalbumRandomImage = getAlbumsImage.filter(function(value, index, array) {
            albumImageArray.push(value.image);
        })
        shuffle(albumImageArray);
        this.setState({ albumImageShruffle: shuffle(albumImageArray) });
        console.log(this.state.albumImageShruffle);
    }

  AlbumsFilter = (currentMonth = new Date().getMonth() + 1, currentYear = new Date().getFullYear()) => {
        var AlbumData = this.state.getAlbums;
        var selectedDate = this.props.onChange===null ? month[currentMonth] + currentYear : this.props.onChange;
        
        if (AlbumData.length) {
            var renderAlbums = AlbumData.filter(function(value, index, array) {
                if (value.album_id !== 0 && value.release_date !== null) {
                    var trackDate = month[new Date(value.release_date).getMonth() + 1] + new Date(value.release_date).getFullYear();
                    if (selectedDate === trackDate) {
                        return value;
                    }
                }else{
                    console.log(value);
                }
                return;
            });

            this.setState({ getallAlbums: renderAlbums.length > 0 ? renderAlbums :AlbumData });
            this.newAlbumRandomImage(this.state.getallAlbums);
        } 
    }


	render(){
		return(
       <>
        <div className = "newrelease_list" >
           <div className="container">
              <div className="row newrelease_title">
                 <div className="col-xs-9">
                    <img src={require("../../images/music.png")} alt="" />
                    <h4>New Releases</h4>
                 </div>
                 <div className="col-xs-3 text-right">
                    <Link to="./seeAll" >
                    SEE ALL </Link>
                 </div>
              </div>
              <Slider {...newReleaseSettings} className="new-release-playlist row">
                 <div className="item">
                    <div className="new-release" onClick={(e) =>
                                           this.props.showAllNewReleaseTracks()}>
                       <div className="playlist-img">
                          {this.state.newReleseImageShruffle.map((newreleseShruffleImage, index) =>
                          <img key={index} src={newreleseShruffleImage} alt="" />
                          )}
                          <span>ALL</span>
                       </div>
                       <h4 className="total-song">{this.state.newReleseImageShruffle.length}</h4>
                       <h3 className="playlist-name">New Releases</h3>
                    </div>
                 </div>
                 <div className="item">
                    <div className="new-singels"  onClick={(e)=>this.props.showSingles(true)}>
                       <div className="playlist-img" >
                          {this.state.SinglesImageShruffle.map((shruffledImage,index) => 
                          <img src={shruffledImage} key={index} alt="" />
                          )}
                          <span>SINGLES</span>
                       </div>
                       <h4 className="total-song">{this.state.getNewSingles.length}</h4>
                       <h3 className="playlist-name">New Singles</h3>
                    </div>
                 </div>
                 <div className="item">
                    <div className="new-album" onClick={(e)=>this.props.showAlbums()}>
                       <div className="playlist-img">
                          {this.state.albumImageShruffle.map((shruffledImage,index) => 
                          <img src={shruffledImage} key={index} alt="" />
                          )};
                          <span>ALBUMS</span>
                       </div>
                       <h4 className="total-song">{this.state.albumImageShruffle.length}</h4>
                       <h3 className="playlist-name">New Albums</h3>
                    </div>
                 </div>
                 <div className="item">
                    <div className="new-release-seeall">
                       <Link to="./seeAll" >
                       SEE ALL </Link>
                    </div>
                 </div>
                 <div className="item"> 
                 </div>
              </Slider>
           </div>
      </div>
    </>)
  }
}

export default NewReleasesLastSection;