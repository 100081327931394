import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {GoogleLogin} from 'react-google-login';
import SpotifyLogin from 'react-spotify-login';
import PropTypes from 'prop-types';
import downarrow from "../images/pink-downarrow.png";
import raisingboth from "../images/raising-both.png";
import Cookies from 'universal-cookie';
import { clientId, redirectUri } from './Settings';
import { 
  Popover,
  Tooltip,
  Modal
} from 'react-bootstrap';

const responseGoogle = (response) => {
  if (response) {
    var localitems = [response.profileObj.name, response.profileObj.imageUrl, response.profileObj.email];
    console.log(localitems);
    localStorage.setItem('Logininfo', localitems);
  }
 
}
const onSuccess = response => spotifyLoginSuccess(response);
const onFailure = response => console.error(response);

const spotifyLoginSuccess = (response) => {
  var spotifyAuth = response;
  console.log(spotifyAuth);
  fetch(`https://api.spotify.com/v1/me`,{
    method: 'GET',
    headers: {
      "Authorization": "Bearer " + spotifyAuth.access_token
    },
  }).then(res => {
    return res.json();
  }).then(responseData => {
    console.log(responseData);
    var localitems=[];
    if(responseData.images.length){
      var localitems=[responseData.display_name, responseData.images[0].url,responseData.email, responseData.id];
    }else{
      var localitems=[responseData.display_name,responseData.email, responseData.id];
    }
    
    localStorage.setItem('Logininfo',localitems);
  });
}

const playlistApi='https://staging.injn.io/secure/playlists';
export class Models extends Component {
  constructor(props, context){
    super(props, context);
    this.handleShowhome =this.handleShowhome!==null ? this.handleShowhome.bind(this) :"";
    this.handleShow = this.handleShow!==null ? this.handleShow.bind(this):"";
    this.handleSpotifyPopup = this.handleSpotifyPopup!==null ? this.handleSpotifyPopup.bind(this): "";
    this.handleSpotifyLogin = this.handleSpotifyLogin!==null ? this.handleSpotifyLogin.bind(this):"";
    this.handleClose = this.handleClose!==null ? this.handleClose.bind(this):"";
    this.state = {
        show: false,
        playlistshow: false,
        spotifyPopup:false,
        spotifyLogin:false,
        youtubeConfirm:false,
        spotifyConfirm:false,
        playlist: '',
        localitems:localStorage.getItem("Logininfo")
    }

  }

handleShow() {
  this.setState({ show: false , playlistshow: true,spotifyPopup:false,spotifyLogin:false,youtubeConfirm:false,spotifyConfirm:false })
}
handleShowhome() {
    this.setState({playlistshow:false,show: true,spotifyPopup:false,spotifyLogin:false,youtubeConfirm:false,spotifyConfirm:false})
}
handleSpotifyPopup(e){
    this.setState({playlistshow:false,show: false,spotifyPopup:true,spotifyLogin:false,youtubeConfirm:false,spotifyConfirm:false})
}
handleSpotifyLogin(){
    this.setState({playlistshow:false,show: false,spotifyPopup:false,spotifyLogin:true,youtubeConfirm:false,spotifyConfirm:false})
}

handleClose(){
    this.setState({ show: false ,playlistshow:false,spotifyPopup:false,spotifyLogin:false,youtubeConfirm:false,spotifyConfirm:false })
  }

playListChange(e){
  this.setState({
          playlist: e.target.value
        });
  }



  createPlaylist(e,data){
  e.preventDefault();
  const newPlaylist={
    playlistname:data
  }
  return fetch(playlistApi, {
        method: "POST",
        credentials: 'same-origin',
        headers:{
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newPlaylist),
    }).then(res => {
        return res;
        alert(res)
    }).catch(err => err);
}

render() {
  return (
    <div>
      {!this.state.localitems ?
        <div className="Models">
        <Modal className="login-method" show={this.state.show} onHide={this.handleClose}>
          <Modal.Header >
            <h3>Choose a Streaming Method</h3>
            <p>You need to connect your streaming account to play music in INJN</p>
          </Modal.Header>
          <Modal.Body>
            <button onClick={((e) => this.handleSpotifyPopup(e))}>Spotify</button>
            <GoogleLogin
              clientId="976477062683-o43hr3t5836iaj8t4tsb9mcefsnv7aps.apps.googleusercontent.com"
              render={renderProps => (
                <button onClick={renderProps.onClick}>YouTube</button>
              )}
              buttonText="Login"
              onSuccess={responseGoogle}
              onFailure={responseGoogle} />
            <button onClick={((e) => this.handleClose())}>Cancel</button>
          </Modal.Body>
        </Modal>
           
        <Modal className="login-method spotify-popup" show={this.state.spotifyPopup} onHide={this.handleClose}>
          <Modal.Header>
            <h3>You need a Spotify premium account i.e. a paid Spotify subscription to stream music in INJN</h3>
            <p>Why? <img src={downarrow} alt="" /></p>
          </Modal.Header>
          <Modal.Body>
            <button onClick={((e) => this.handleSpotifyLogin())}>Sign in with Spotify Premium</button>
            <GoogleLogin
              clientId="976477062683-o43hr3t5836iaj8t4tsb9mcefsnv7aps.apps.googleusercontent.com"
              render={renderProps => (
                <button onClick={renderProps.onClick}>Choose YouTube (Free)</button>
              )}
              buttonText="Login"
              onSuccess={responseGoogle}
              onFailure={responseGoogle} />
            <button onClick={((e) => this.handleClose())}>Cancel</button>
          </Modal.Body>
        </Modal>
        <Modal className="spotifylogin" show={this.state.spotifyLogin} onHide={this.handleClose}>
          <Modal.Header >
            <h3>“INJN” wants to open  “Spotify”</h3>
          </Modal.Header>
          <Modal.Body>
            <button onClick={((e) => this.handleClose())}>Cancel</button>
            <SpotifyLogin clientId='0ba936c2dcb34cf5b823b0f93da242f7'
              clientSecret="1ace8861864f4c8d9265f8d61a44fd5d"
              redirectUri='http://injn.dev.project-progress.net/'
              buttonText="Open"
              onSuccess={onSuccess}
              onFailure={onFailure} />
          </Modal.Body>
        </Modal>
        <Modal className="youtube-confirm" show={this.state.youtubeConfirm} onHide={this.handleClose}>
          <Modal.Body>
            <img src={raisingboth} alt="" />
            <p>Streaming by YouTube Connected Successfully</p>
          </Modal.Body>
        </Modal>
        <Modal className="spotify-confirm" show={this.state.spotifyConfirm} onHide={this.handleClose}>
          <Modal.Body>
            <img src={raisingboth} alt="" />
            <p>Spotify Premium Connected Successfully</p>
          </Modal.Body>
        </Modal>
        <Modal className="newplaylist_popup" show={this.state.playlistshow} onHide={this.handleClose}>
          <Modal.Header>
            <h3>Create New Playlist</h3>
            <p>Enter a name for this new playlist</p>
            <input type="text" placeholder="Playlist Name" name="playlist" onChange={((e) => this.playListChange(e, this.state.playlist))} />
          </Modal.Header>
          <Modal.Body>
            <button onClick={((e) => this.handleClose())}>Cancel</button>
            <button onClick={((e) => this.createPlaylist(e, this.state.playlist))}>Create</button>
          </Modal.Body>
          </Modal>
          </div>:''
        }
  
        </div>
    )
  }
}
