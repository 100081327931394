import React, { Component,createRef } from 'react';
import { Redirect,Link } from 'react-router-dom';
import { Header } from "./Header";
import { Footer } from "./Footer";
import { seeAll } from './home-content/seeAll';
import SpotifyAuthentication from './authentication/SpotifyAuthentication';
import Albums from './home-content/Albums';
import NewSingles from "./home-content/NewSingles";
import NewRealise from "./home-content/NewRealise";
import TrendingTracks from "./home-content/TrendingTracks";
import NewReleasesGeneres from "./home-content/NewReleasesGeneres";
import NewReleasesMidSection from "./home-content/NewReleasesMidSection";
import NewReleasesLastSection from "./home-content/NewReleasesLastSection";
import $ from 'jquery';
import Slider from "react-slick";
import Calendar from 'react-calendar';
import { GoogleLogin } from 'react-google-login';
import SpotifyLogin from 'react-spotify-login';
import Cookies from 'universal-cookie';
import { clientId, redirectUri } from './Settings';
import { Popover, Tooltip, Modal } from 'react-bootstrap';
import Palette from 'react-palette';
import YouTube from 'react-youtube';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { Media, Player, controls, utils } from 'react-media-player'
import { Splash } from "./splash"
import DatePicker from 'react-mobile-datepicker';
import InitPlayer from './spotifyPlayer/InitPlayer';
import Apis from './Apis';
import TestSpotify from './spotifyPlayer/TestSpotify';
import NowPlaying from './spotifyPlayer/NowPlaying';
import {play} from './spotifyPlayer/PlayerConst';
import {month,shortMonth,fullYear} from '../constants/TimeStamp';

const {
    PlayPause,
    CurrentTime,
    Progress,
    SeekBar,
    Duration,
    MuteUnmute,
    Volume,
    Fullscreen,
} = controls;
const host = /http[s]?:\/\/[^/]+/.exec(redirectUri)[0];
const opts = {
    height: '390',
    width: '640',
    playerVars: { // https://developers.google.com/youtube/player_parameters
        autoplay: 1
    }
};

const { keyboardControls } = utils;
  // Slick settings//        
class Home extends Component {
    constructor(props) {
        super(props);
        console.log(props);
        this.handleShowhome = this.handleShowhome.bind(this);
        this.handleSpotifyPopup = this.handleSpotifyPopup.bind(this);
        this.handleSpotifyLogin = this.handleSpotifyLogin.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.updateSpotifyStatus = this.updateSpotifyStatus.bind(this);
        this.updateSpotifyLoginStatus = this.updateSpotifyLoginStatus.bind(this);
        this.handleSelect=this.handleSelect.bind(this);
        this.showAllSingles=this.showAllSingles.bind(this);
        this.showAllNewReleaseTracks=this.showAllNewReleaseTracks.bind(this);
        this.showAlbums=this.showAlbums.bind(this);
        this.state = {
            splash: true,
            time: new Date(),
            isOpen: false,
            theme: 'default',
            popularGenresRelease: [],
            artistBkImage: [],
            isLoaded: false,
            homePageLoad: true,
            dataLoaded: false,
            SinglesLoaded: false,
            AlbumsLoaded: false,
            isFeatured: [],
            show: false,
            spotifyPopup: false,
            spotifyLogin: false,
            youtubeConfirm: false,
            spotifyConfirm: false,
            userInfo: false,
            LoginConfirm: false,
            localSet: localStorage.getItem("Logininfo"),
            dateChange: false,
            releaseGenresLoaded: false,
            artistBkImage: "https://images.pexels.com/photos/248797/pexels-photo-248797.jpeg",
            allMonth: '',
            getYear: '',
            getCurrentMonth: '',
            currentYear: '',
            popularGenres: '',
            isFeaturedData: '',
            GetNewRelesesGenres: '',
            videoPause: false,
            storeMonth: '',
            storeYear: '',
            getNewSingles: [],
            dateonchange:'',
            checkDateStatus:'',
            changedDate:false,
            loginStatus:localStorage.getItem('loginStatus')!==null ? true : false,
            newDateStatus:null
        }
        
    }

    async updateSpotifyStatus(statusspotifyconfirmed){
        try {
        const status=await statusspotifyconfirmed;
        console.log(status);
        this.setState({spotifyConfirm:status})
         setTimeout(() => {
                this.setState({spotifyConfirm: false });
          }, 2000)
        }
        catch(error){
            console.log(error); 
        }
    }

    updateSpotifyLoginStatus(statusspotifylogin,loginStatus){
        console.log(statusspotifylogin);
        this.setState({spotifyLogin:statusspotifylogin,loginStatus:loginStatus})
    }

    componentDidMount() {
//        console.log(this.refs, 'ref')
        this.setState({ splash: true });
        localStorage.setItem("splash",true)
        const currentD = new Date();
        if (localStorage.getItem("splash") === "false") {
            //console.log("true");
        }
        var currentMonth = shortMonth[currentD.getMonth()];
        var prevMonth = [];
        var getYearbasedMonth = currentD.getMonth();
        for (var i = 0; i <= getYearbasedMonth; i++) {
            var actualpreviousMonth = shortMonth[i];
            prevMonth.push(actualpreviousMonth);
        }
        if (currentD.getFullYear()) {
            this.setState({ dateChange: false, currentMonth: currentMonth, allMonth: prevMonth, getYear: fullYear, currentYear: currentD.getFullYear() });
        } else {
            this.setState({ dateChange: false, currentMonth: currentMonth, allMonth: month, getYear: fullYear, currentYear: currentD.getFullYear() });
        }
        this.setState({ getCurrentMonth: currentMonth, storeMonth: localStorage.setItem("month", currentD.getMonth() + 1), storeYear: localStorage.setItem("year", currentD.getFullYear()) });

        var self = this;
        //authentication 
        if (this.state.localSet) {
            var loadeddata = [this.state.localSet];
            var infoVal = loadeddata[0].split(",");
            this.setState({ show: false, LoginConfirm: true, userInfo: infoVal });
        }


        // featured release carousel
        fetch(Apis.isFeaturedApi.url, {
            method: Apis.isFeaturedApi.method
        }).then(results => {
            return results.json()
        }).then(response => {
            //console.log(response);
            //self.setState({ isLoaded: true, isFeatured: response.data});
            self.setState({ isFeaturedData: response });
            self.featuredReleaseByFilter();
        });
    }

//date Changes<img src=`{downArrow}` alt=''/><img src=`{upArrow}` alt=''/>"//
 handleToggle = (isOpen) => () => {
        this.setState({ isOpen });
    };

    handleThemeToggle = (theme) => {
        this.setState({ theme, isOpen: true });
    };

    handleSelect = (time) => {
        this.setState({isLoaded:false})
        var selectDate = time.toString().split(" ");
        var selectedChangeddate={
                date:selectDate[1]+selectDate[3]
        };
        var setDate={
            date:selectDate[1]+" "+"-"+" "+ selectDate[3] 
        };
        this.setState({newDateStatus:selectedChangeddate.date,time, isOpen: false});
        let self=this;
        setTimeout(function(){
            self.setState({isLoaded:true})
            console.log(self.state.newDateStatus);
        },1000)
        console.log(setDate.date);
    }


    featuredReleaseByFilter = (currentMonth = new Date().getMonth() + 1, currentYear = new Date().getFullYear()) => {
        var featuredData = this.state.isFeaturedData;
        var selectedDate = month[currentMonth] + currentYear;
        var self = this;
        if (featuredData.length) {
            var renderFeatured=this.state.isFeaturedData
            var renderFeatured = featuredData.filter(function(value, index, array) {
                if (value.album.release_date !== null) {
                    var trackDate = month[new Date(value.album.release_date).getMonth() + 1] + new Date(value.album.release_date).getFullYear();
                    if (selectedDate === trackDate && value.is_featured === "true") {
                        return value;
                    }
                }
                return;
            });
            //console.log(renderFeatured);
            self.setState({ isLoaded: true, isFeatured: renderFeatured });
        } else {
            self.setState({ isFeatured: featuredData });
        }
    }

    showAllNewReleaseGenresRefrencedTracks = (e, Releasegenres) => {
        //console.log("Releasegenres", Releasegenres.name);
        var Genrename = Releasegenres.name;
        var artist = "artists"
        var GenresInfo = `https://staging.injn.io/secure/genres/${Genrename}/${artist}`
        //console.log(GenresInfo);
        fetch(GenresInfo, {
            method: "GET"
        }).then(results => {
            return results.json();
        }).then(genresdata => {
            //console.log(genresdata)
            let Genresdata = genresdata;
            if (genresdata !== "" || genresdata.artistsPagination.data.length !== "") {
                this.setState({ allGenresdata: Genresdata });
                let allGenresdata = <div className="relesegenres" key={this.state.allGenresdata.genre.id}>
             <div className="row">
               <div className="col-xs-8 text-left">
                 <a href="javascript:void(0)" className="back_icon" onClick={((e) => { this.returnHome() })}><img src={require("../images/back.png")} alt="" /></a>
               </div>
             </div>
             <div className="album_image Genre-image">
                      <img src={this.state.allGenresdata.artistsPagination.data[0].image_large} alt="" />
             </div>
             <div className="album_song_detail">
               <div className="row">
                 <div className="col-xs-9">
                   <h2 className="album_title">{this.state.allGenresdata.artistsPagination.data[0].name}</h2>
                 </div>
                 <div className="col-xs-3 text-right">
                   <a linkto=""><img src={require("../images/open-shuffel.png")} alt="" /> </a>
                 </div>
               </div>
             </div>
           </div>

                this.setState({ isLoaded: true, dataLoaded: false, homePageLoad: false, releaseGenresLoaded: true, GetNewRelesesGenres: allGenresdata })
            } else {
                //console.log("empty")
            }
        });

    }

    //Showing add Relesed Tracks while click on all New Relesed Tab//

    showAllNewReleaseTracks = (e) => {
        this.setState({ isLoaded: true, dataLoaded: true,homePageLoad: false})
    }

    showAllSingles = (e) => {
        this.setState({ isLoaded: true, dataLoaded: false, SinglesLoaded: true,homePageLoad: false, })
    }

    showAlbums=()=>{
        this.setState({ dataLoaded: false,AlbumsLoaded: true, homePageLoad: false})
    }

    handleShowhome(openpopup) {
        this.setState({ show: openpopup, playlistshow: false, spotifyPopup: false, spotifyLogin: false, youtubeConfirm: false, spotifyConfirm: false })
    }

    handleSpotifyPopup(e) {
        this.setState({ playlistshow: false, show: false, spotifyPopup: true, spotifyLogin: false, youtubeConfirm: false, spotifyConfirm: false })
    }

    handleSpotifyLogin() {
        this.setState({ playlistshow: false, show: false, spotifyPopup: false, spotifyLogin: true, youtubeConfirm: false, spotifyConfirm: false })
    }

    handleClose() {
        this.setState({ show: false, playlistshow: false, spotifyPopup: false, spotifyLogin: false, youtubeConfirm: false, spotifyConfirm: false })
    }

    // Return to home page after going to newReleses slider pages

    returnHome() {
        this.setState({ dataLoaded: false, homePageLoad: true, AlbumsLoaded: false, SinglesLoaded: false, releaseGenresLoaded: false, releaseGenresLoaded: false });
    }

    pauseVideo(e) {
        $('.media-player')[0].contentWindow.postMessage('{"event":"command","func":"' + 'pauseVideo' + '","args":""}', '*');
        this.setState({ videoPause: true });
    }
    playVideo(e) {
        $('.media-player')[0].contentWindow.postMessage('{"event":"command","func":"' + 'playVideo' + '","args":""}', '*');
        this.setState({ videoPause: false });
    }

    render() {
        var { localitems, isLoaded, selectedYear, selectedMonth, dateChange, currentYear, currentDate } = this.state;
        if (!localStorage.getItem("splash") === true) {
            return <div className="splash">
                      <Splash/>
                  </div>
        }


        // Google login data
        const googleLogin = response => responseGoogle(response);
        const onFailure = response => console.error(response);
        const responseGoogle = (response) => {
            //console.log(response);
            var googleResponse=response;
             if (googleResponse) {
                this.setState({ show: false, playlistshow: false, spotifyPopup: false, spotifyLogin: false, youtubeConfirm: false, spotifyConfirm: true });
                setTimeout(() => {
                    this.setState({ show: false, playlistshow: false, spotifyPopup: false, spotifyLogin: false, youtubeConfirm: false, spotifyConfirm: false });
                }, 2000)
                localitems = [response.profileObj.name, response.profileObj.imageUrl, response.profileObj.email];
                localStorage.setItem('Logininfo', localitems);
                localStorage.setItem("GoogleUser","googleLoggedIn");

                this.setState({ show: false, LoginConfirm: true, userInfo: localitems });
             }
         }
        //sportifu Login data
        //console.log(this.state.time);
        const dateConfig = {
            'month': {
                format: value => month[value.getMonth()],
                caption: 'Month',
                step: 1,
            },
            'year': {
                caption: 'Year',
                step: 1,
            },
            
        };

        if (!isLoaded && localStorage.getItem("splash") === "false") {
            return <div className="loading"><img src={require("../images/loading.gif")} alt=""/></div>
        } else {
            return (
                <div className="Home-page noshade" ref="Home-page">
                  {this.state.releaseGenresLoaded ? <div className='container'>{this.state.GetNewRelesesGenres}</div> : ""}
                  {this.state.dataLoaded && <NewRealise/>}
                  {this.state.SinglesLoaded && <NewSingles/>}
                  {this.state.AlbumsLoaded && <Albums/>} 
                 {this.state.homePageLoad ?
                <div className="home-page">
                      <Palette image={this.state.artistBkImage}>
                        {palette => (
                          <header sticky="top" id="navbar" style={{ backgroundColor: palette.vibrant }}>
                            <Header imgurl={this.state.artistBkImage} />
                          </header>
                        )}
                      </Palette>
              <div id="calender">
                <div className="cal-wrap">
                   <a className="select-btn sm" onClick={((e)=>this.handleThemeToggle('ios'))}>{this.state.changedDate ? this.state.dateonchange : this.state.currentMonth+" " +"-"+" "+this.state.currentYear}</a>
                    </div>
                      <DatePicker
                            showHeader={false}
                            value={this.state.time}
                            dateConfig={dateConfig}
                            confirmText={"Done"}
                            cancelText={"Cancel"}
                            theme={this.state.theme}
                            isOpen={this.state.isOpen}
                            onSelect={this.handleSelect}
                            onCancel={this.handleToggle(false)} />
                  </div>
              <section className="main">
                <NewReleasesGeneres onChange={this.state.newDateStatus}/>
                <NewReleasesMidSection onChange={this.state.newDateStatus}/>
                <NewReleasesLastSection showSingles={this.showAllSingles} showAllNewReleaseTracks={this.showAllNewReleaseTracks} showAlbums={this.showAlbums} onChange={this.state.newDateStatus}/>
                <TrendingTracks loginpopup={this.handleShowhome} onChange={this.state.newDateStatus}/>
                </section>
                
                <div className="login-module"> {!this.state.LoginConfirm ?
                    <div className="Models">
                        <Modal className="login-method" show={this.state.show} onHide={this.handleClose}>
                          <Modal.Header >
                            <h3>Choose a Streaming Method</h3>
                            <p>You need to connect your streaming account to play music in INJN</p>
                          </Modal.Header>
                          <Modal.Body>
                        <button onClick={this.handleSpotifyPopup}>Spotify</button>
                        <GoogleLogin
                          clientId="280713550860-h794gleg239hpkjb9js2jjk8b0687l7h.apps.googleusercontent.com"
                          render={renderProps => (
                            <button onClick={renderProps.onClick}>YouTube</button>
                          )}
                          buttonText="Login"
                          //uxMode = "popup"
                          //redirectUri = "http://injn.dev.project-progress.net"
                          onSuccess={googleLogin}
                          onFailure={onFailure} />
                        <button className="model-close" onClick={((e) => this.handleClose())}>Cancel</button>
                      </Modal.Body>
                    </Modal>
                    <Modal className="login-method spotify-popup" show={this.state.spotifyPopup} onHide={this.handleClose}>
                      <Modal.Header>
                        <h3>You need a Spotify premium account i.e. a paid Spotify subscription to stream music in INJN</h3>
                        <Link to="/">Why? <img src={require('../images/dropdown-arrow-01.png')} alt="" /></Link>
                        <div className="why-descrip">
                          <ul>
                            <li>Spotify requires a Premium subscription in order to stream music outside of their own apps.</li>
                            <li>We are here to support and contribute to the culture. Every time you play a song on INJN, the artist(s) gets paid.</li>
                          </ul>
                        </div>
                      </Modal.Header>
                      <Modal.Body>
                        <button onClick={((e) => this.handleSpotifyLogin())}>Sign in with Spotify Premium</button>
                        <GoogleLogin
                          clientId="280713550860-h794gleg239hpkjb9js2jjk8b0687l7h.apps.googleusercontent.com"
                          render={renderProps => (
                            <button onClick={renderProps.onClick}>Choose YouTube (Free)</button>
                          )}
                          buttonText="Login"
                          //uxMode = "popup"
                          //redirectUri = "http://injn.dev.project-progress.net"
                          onSuccess={googleLogin}
                          onFailure={onFailure} />
                        <button className="model-close" onClick={((e) => this.handleClose())}>Cancel</button>
                      </Modal.Body>
                    </Modal>

                  <Modal className="spotifylogin" show={this.state.spotifyLogin} onHide={this.updateSpotifyLoginStatus}>
                      <Modal.Header >
                        <h3>“INJN” wants to open  “Spotify”</h3>
                      </Modal.Header>
                      <Modal.Body>
                        <button onClick={((e) => this.handleClose())}>Cancel</button>
                        {this.state.spotifyLogin && <SpotifyAuthentication updateSpotifyStatus={this.updateSpotifyStatus} updateSpotifyLoginStatus={this.updateSpotifyLoginStatus}/>}
                      </Modal.Body>
                    </Modal>
                    <Modal className="youtube-confirm" show={this.state.youtubeConfirm} onHide={this.handleClose}>
                      <Modal.Body>
                        <img src={require("../images/raising-both.png")} alt="" />
                        <p>Streaming by YouTube Connected Successfully</p>
                      </Modal.Body>
                    </Modal>
                    <Modal className="spotify-confirm" show={this.state.spotifyConfirm} onHide={this.updateSpotifyStatus}>
                      <Modal.Body>
                        <img src={require("../images/raising-both.png")} alt="" />
                        <p>Spotify Premium Connected Successfully</p>
                      </Modal.Body>
                    </Modal>

                  </div> : ''
                } </div> 
             </div> :
                ""
                 }
                <Footer checkdata={this.state.LoginConfirm} data={this.state.userInfo} /> 
            </div>
        );
    }
    
}

}
export default Home;