import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import $ from 'jquery';
import PropTypes from 'prop-types';
import {BrowserRouter,Route,browserHistory,Link} from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Carousel } from 'react-responsive-carousel';
import back from "../../images/back.png";
import settings from '../../images/settings.png';
import  rightArrow from "../../images/right-arrow.png";
import  camera from "../../images/camera-icon.png";
import  addprofile from "../../images/addprofile-pic.png";
import  removeprofile from "../../images/minusprofile-pic.png";
import {Header} from "../Header";
import {Footer} from "../Footer";
import {Popover,Tooltip,Modal} from 'react-bootstrap';
//import axios from 'axios';


export class Editprofile extends Component{ 
    constructor(props) {
        super(props)
        this.browseimagePopup = this.browseimagePopup.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.state = {
            infoVal: false,
            Logininfo: false,
            selectedFile: null,
            browse_image: false,
            getProfilepic: "",
            hasImage: false,
            removepic:false,
        }
    }

    componentDidMount() {
        localStorage.getItem("Logininfo") && this.setState({
            Logininfo: localStorage.getItem("Logininfo"),
            infoVal: true
        })

        /*  var camera = document.getElementById("upfile1");
         camera.addEventListener('click', function (e) { alert();e.target.previousSibling.click()}); */ 
    }   

    profileUpdateImage(e) {
        console.log(e.target.files[0]);
        const ImageFile = e.target.files[0]
        this.setState({ hasImage:true,getProfilepic: URL.createObjectURL(ImageFile),browse_image:false,removepic :true });
    }

    removeImage(e) {
        document.getElementById("getProfilepic").src = null;
        this.setState({ removepic: false,hasImage:false });
    }
    openImageFolder(e) {
        e.target.previousSibling.click()
    }
    browseimagePopup() {
        this.setState({browse_image: true})
    }
    handleClose(){
        this.setState({browse_image:false})      
    }
    render() {  
         var {infoVal,Logininfo,allInfo,getProfilepic,hasImage,removepic}=this.state;

         if (this.state.infoVal) {
             const Info = [Logininfo];
             const split = Info[0] == null ? "" : Info[0].split(",");
             allInfo = {
                 name: split[0],
                 img: split[1]
             }
         }
  return (
    <div className="Usersetting noshade">  
        <div className="container">
            <div className="album_header">
                <div className="row">
                    <div className=" col-xs-1 text-center">
                            <Link to="./Userprofile"><img src={back} alt=""/></Link>
                    </div>
                    <div className="col-xs-11 text-center">
                            <h3>Update Name & Profile Image</h3>
                    </div>
                </div>
            </div>  
            <div className="user-edit">
                <div className="text-form-group">
                    <label>Full name</label>
                    <input type="text" placeholder="Jason Ali"></input>
                </div>
                <div className="image-form-group">
                    <label>PROFILE IMAGE</label>
                    <div className="profile_image">
                        <img className="addprofilepic" onClick={this.browseimagePopup} src={addprofile} alt="" />
                        {removepic ?
                        <img className="removeprofilepic" src={removeprofile} alt="" onClick={((e)=>this.removeImage(e))} />:""}
                          <button className={this.state.hasImage ? "cameraimage hasImg" :"cameraimage"} onClick={this.browseimagePopup} >
                            { hasImage ? <img id="getProfilepic" src={getProfilepic} alt="" /> :
                              <img src={camera} alt="" />}
                          </button>
                    </div>
                </div>
                <button className="save-button">save</button>
            </div>      
        </div>
         
          <Modal className="browseimage_popup" show={this.state.browse_image} onHide={this.handleClose}>
                        <Modal.Header>
                            <h3>Upload Profile Image</h3>
                            <div className="browse-profile">
                                <input type="file" name="file" accept="image/*" onChange={((e)=>this.profileUpdateImage(e))} />
                                <p id="upfile1" onClick={((e)=>this.openImageFolder(e))}>Choose From Library</p>
                            </div>
                            <p>Take Photo</p>
                        </Modal.Header>
                        <Modal.Body>
                        <button onClick={((e) => this.handleClose())}>Cancel</button>
                        </Modal.Body>
                        
                </Modal>
       <Footer/>
    </div>
);
}
}   