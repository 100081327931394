import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import {BrowserRouter,Route,browserHistory,Link} from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Carousel } from 'react-responsive-carousel';
import back from "../../images/back.png";
import settings from '../../images/settings.png';
import  rightArrow from "../../images/right-arrow.png";
import {Header} from "../Header";
import {Footer} from "../Footer";



export class Usersetting extends Component{ 
    constructor(props) {
        super(props)
        this.state = {
            infoVal: false,
            Logininfo: false,
        }
    }
    componentDidMount() {
        localStorage.getItem("Logininfo") && this.setState({
            Logininfo: localStorage.getItem("Logininfo"),
            infoVal: true
        })
    }
    render() {  
         var {infoVal,Logininfo,allInfo}=this.state;

         if (this.state.infoVal) {
             const Info = [Logininfo];
             const split = Info[0] == null ? "" : Info[0].split(",");
             allInfo = {
                 name: split[0],
                 img: split[1]
             }
         }
  return (
    <div className="Usersetting noshade">  
        <div className="container">
            <div className="album_header">
                <div className="row">
                    <div className=" col-xs-1 text-center">
                            <a href="javascript:void(0)" className=""><img src={back} alt=""/></a>
                    </div>
                    <div className="col-xs-11 text-center">
                            <h3>Settings</h3>
                    </div>
                </div>
            </div>  
            <div className="user-opetion">
                <ul>
                    <li> <label>Update Name & Profile Image </label> <a href="./editprofile" className="text-right"><img src={rightArrow} alt=""/></a></li>
                    <li> <label>Manage Social Logins </label> <a href="" className="text-right"><img src={rightArrow} alt=""/></a></li>
                    <li> <label>Update Password </label> <a href="" className="text-right"><img src={rightArrow} alt=""/></a></li>
                    <li> <label>Update Account Preferences</label> <a href="" className="text-right"><img src={rightArrow} alt=""/></a></li>
                </ul>
            </div>      
        </div>
       <Footer/>
    </div>
);
}
}