import React, { Component } from 'react';
import {Link,Redirect} from 'react-router-dom';
import {shuffle} from '../ApiCall';
import Apis from '../Apis';
import {month,shortMonth} from '../../constants/TimeStamp';
import TestSpotify from '../spotifyPlayer/TestSpotify';
import {player} from '../spotifyPlayer/Apicalls';

let songsurl=[];
class NewRealise extends Component{ 
    constructor(props){
        super(props);
        this.state={
            newReleseImageShruffle: [],
            newReleaseGenre: [],
            newreleases:[],
            playerStatus:false,
        }
      

    }

    componentDidMount(){
       fetch(Apis.apiNewreleases.url, {
            method: Apis.apiNewreleases.method
        }).then(results => {
            return results.json()
        }).then(newReleasedata => {
            console.log(newReleasedata);
            this.setState({ newReleaseGenre: newReleasedata });
            this.newReleaseByFilter();
            songsurl=[];
            this.state.newReleaseGenre.map(item=>{songsurl.push(item.uri)})
        })
    }

    newReleaseByFilter = (currentMonth = new Date().getMonth() + 1, currentYear = new Date().getFullYear()) => {
        var newReleaseData = this.state.newReleaseGenre;
        var selectedDate = month[currentMonth] + currentYear;
        /*if (newReleaseData.length) {
            var renderNewRelease = newReleaseData.filter(function(value, index, array) {
                if (value.release_date !== null) {
                    var trackDate = month[new Date(value.release_date).getMonth() + 1] + new Date(value.release_date).getFullYear();
                    if (selectedDate === trackDate) {
                        return value;
                    }
                }
                return;
            });
            this.setState({ newreleases: renderNewRelease });
            this.newReleaseRandomImage(renderNewRelease);
        } else {*/
            this.setState({ newreleases: newReleaseData });
            this.newReleaseRandomImage(newReleaseData);
        //}
    }

     newReleaseRandomImage = (newRelasesRandom) => {
        var releaseImageArray = [];
        var getNewReleaseRandomImage = newRelasesRandom.filter(function(value, index, array) {
            releaseImageArray.push(value.image);
        })
        shuffle(releaseImageArray);
        this.setState({ newReleseImageShruffle: shuffle(releaseImageArray) });
    }

    playTrack=()=>{
      /*setTimeout(()=>{
            player(localStorage.getItem('_spharmony_device_id'),songsurl,localStorage.getItem('spotify_access_token')) ;
        },2000)
        this.setState({playerStatus:true});*/
    }

   
    render(){     
        return (
          <div>
          {!this.state.playerStatus ? 
            <div className="container">
              <div className="row">
                <div className="col-xs-8 text-left right">
                  <a href="javascript:void(0)" className="back_icon"><img src={require('../../images/back.png')} alt="" /></a>
                </div>
              </div>
              <div className="album_image">
                {this.state.newReleseImageShruffle.slice(0,4).map((newreleseShruffleImage, index) =>
                              <img key={index} src={newreleseShruffleImage} alt="" />
                            )}
              </div>
              <div className="album_song_detail">
                <div className="row">
                  <div className="col-xs-9">
                    <h2 className="album_title"></h2>
                    <p><span>{this.state.newReleseImageShruffle.length} Songs</span>.<span>{}</span>.<span>2019</span></p>
                  </div>
                  <div className="col-xs-3 text-right">
                    <a linkto=""><img src={require('../../images/open-shuffel.png')} alt="" /> </a>
                  </div>
                </div>
              </div>
              <div className="allnewReleaseInfo">
              <div className="album_song_list">
                {this.state.newreleases.map((allnewreleasetracks,index) =>
                    <div className="song-list row" key={index} onClick={(e)=>this.playTrack()}>
                      <div className="col-xs-12">
                        <div className=" row_inner text-left">
                          <h4>{allnewreleasetracks.name}</h4>
                          <span>{/*allnewreleasetracks.artist.name ? allnewreleasetracks.artist.name :''*/}</span>
                        </div>
                      </div>
                    </div>
                      
                )}</div>
                </div>
            </div>
            :
              <div className="container">
                  <div className="row">
                      <TestSpotify/>
                  </div>
              </div>
          }

      </div>
          );
    }
}

export default NewRealise;