import React, { Component,createRef } from 'react';
import { Redirect } from 'react-router-dom';
import SpotifyLogin from 'react-spotify-login';
import Home from '../Home'
//const host = /http[s]?:\/\/[^/]+/.exec(redirectUri)[0];
const scopes = ["streaming","user-read-email","user-read-private","user-library-read","user-library-modify","user-read-playback-state","user-modify-playback-state",
];



class SpotifyAuthentication extends Component{
	constructor(props){
		super(props)
		this.state={
			spotifyLogin:false,
			//spotifyConfirm:false
		}
	}
	spotifyLoginSuccess = (response) => {
		            var spotifyAuth = response;
		            console.log(spotifyAuth);
		            if (spotifyAuth) {
		                this.setState({spotifyLogin: false, spotifyConfirm: true });
		            }

		            // Refresh Token//
		            fetch(`https://api.spotify.com/v1/me/`, {
		                method: 'GET',
		                headers: {
		                    "Authorization": "Bearer " + spotifyAuth.access_token
		                },
		            }).then(res => {
		                return res.json();
		            }).then(responseData => {
		                console.log(responseData);
		                var localitems = [];
		                var access_token = spotifyAuth.access_token
		                var sportifyAccessToken = localStorage.setItem("spotify_access_token", access_token);
		                var sportifylogin=localStorage.setItem("spotifyUser","spotifyLoggedIn");

		                if (responseData.images.length) {
		                    var localitems = [responseData.display_name, responseData.images[0].url, responseData.email, responseData.id];
		                } else {
		                    var localitems = [responseData.display_name, responseData.email, responseData.id];
		                }
		                localStorage.setItem('Logininfo', localitems);
		                localStorage.setItem('loginStatus',true);
		                this.props.updateSpotifyStatus(this.state.spotifyConfirm);
		                this.props.updateSpotifyLoginStatus(this.state.spotifyLogin,localStorage.getItem('loginStatus'));
		                this.setState({ show: false, LoginConfirm: true, userInfo: localitems });
		            });
		 }


	render(){
		const onSuccess = response => this.spotifyLoginSuccess(response);
		const onFailure = response => console.error(response);

		return (<SpotifyLogin clientId='fd3aecacc43e48ab9e211ff4470b62ec'
                    redirectUri = {window.location.host === 'localhost:3006' ? 'http://localhost:3006' : 'https://injn.dev.project-progress.net'}
                    scope = {scopes}
                    buttonText="Open"
                    onSuccess={onSuccess}
                    onFailure={onFailure}
                    />
                )
	}
}

export default SpotifyAuthentication;