import styled from 'styled-components';

export const Button = styled.button`
  -webkit-appearance: none;
  background-color: #1db954;
  border: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  color: #fff;
  font-size: 22px;
  line-height: 1;
  padding: 4px 12px;
`;

export const Disclaimer = styled.div`
  color: #444;
  font-size: 13px;
  margin: 5px auto 0;
  max-width: 320px;
  text-align: center;

  p {
    font-size: 18px;
    font-weight: bold;
  }

  a {
    color: #1db954;
  }
`;

export const Form = styled.form`
  display: flex;
  justify-content: center;
  text-align: center;
  width: 100%;
`;

export const Heading = styled.h1`
  color: #1db954;
  font-size: 24px;
  margin-bottom: 40px;
  margin-top: 0;
  padding-top: 60px;
  text-align: center;
`;

export const Input = styled.input`
  -webkit-appearance: none;
  border: 1px solid #1db954;
  border-right: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  box-sizing: border-box;
  font-size: 18px;
  line-height: 1;
  width: 240px;
  padding: 6px 12px;
`;

export const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const Player = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
`;

export const ScopeTitle = styled.h4`
  margin: 10px 0;
`;

export const Selector = styled.div`
  margin: 30px auto 0;
  max-width: 320px;
  text-align: center;

  button {
    appearance: none;
    background-color: #1db954;
    border: 0;
    color: #fff;
    display: inline-flex;
    font-size: 16px;
    margin: 0 0 10px;
    padding: 6px 10px;

    + button {
      margin-left: 10px;
    }
  }
`;
