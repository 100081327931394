import React, { Component, Fragment } from 'react';
import WebPlaybackReact from './WebPlaybackReact.js';
import NowPlaying from './NowPlaying.js';
import { hot } from 'react-hot-loader';

let webPlaybackSdkProps;
window.onSpotifyWebPlaybackSDKReady = () => {};

class TestSpotify extends Component {
  constructor(props){
  super(props);
  console.log(props.songuri)
  this.state = {
    // User's session credentials
    userDeviceId: null,
    userAccessToken: localStorage.getItem('spotify_access_token'),
    loginStatus:localStorage.getItem('loginStatus')!==null ? true : false,  
    // Player state
    playerLoaded: false,
    playerSelected: false,
    playerState: null,
    songs:props.songuri,
  }

}
  componentWillMount() {
    if(this.state.loginStatus){
     console.log(this.state.playerLoaded ,this.state.playerState)
        let {
      userDeviceId,
      userAccessToken,
      playerLoaded,
      playerSelected,
      playerState
    } = this.state;
    webPlaybackSdkProps = {
      playerName: "Spotify React Player",
      playerInitialVolume: 1.0,
      playerRefreshRateMs: 100,
      playerAutoConnect: true,
      onPlayerRequestAccessToken: (() => userAccessToken),
      onPlayerLoading: (() => this.setState({ playerLoaded: true })),
      onPlayerWaitingForDevice: (({ device_id }) => this.setState({ playerSelected: false, userDeviceId: device_id })),
      onPlayerStateChange: (playerState => this.setState({ playerState })),
      onPlayerError: (playerError => console.error(playerError))
      };
    }
    console.log(webPlaybackSdkProps.onPlayerStateChange);
  }

  
  render() {
    return (
          <div> 
              {this.state.loginStatus && <WebPlaybackReact {...webPlaybackSdkProps}>
                  <Fragment>
                    <NowPlaying playerState={this.state.playerState}  />
                      {/*this.state.playerState &&
                        <Fragment>
                          <NowPlaying playerState={this.state.playerState}  />
                        </Fragment>
                      */}
                  </Fragment>
            </WebPlaybackReact>}
        </div>
      );
    }
}

export default process.env.NODE_ENV === 'development' ? hot(module)(TestSpotify) : TestSpotify;


