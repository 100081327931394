export const user_access_token=localStorage.getItem('spotify_access_token');

export function shuffle(array){
	  var ctr = array.length,
            temp, index;
        while (ctr > 0) {
            index = Math.floor(Math.random() * ctr);
            ctr--;
            temp = array[ctr];
            array[ctr] = array[index];
            array[index] = temp;
        }
        return array;
}

