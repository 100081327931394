import React, {Component} from 'react';
import spalsbg from "../images/spalsbg.png";
import splashlogo from "../images/splash-logo.png";
export class Splash extends Component{ 
     render(){
        return (
        <div className="splash">
                <img className="splash-bg" src={spalsbg} />
                <img className="splash-logo" src={splashlogo} />
         </div>
);
}

}



