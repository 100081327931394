import React, {Component} from 'react';
import {BrowserRouter,Route,browserHistory,Link} from 'react-router-dom';
import searchIcon from "../../images/search-icon.png";
import back from "../../images/back.png";
import  rightArrow from "../../images/right-arrow.png";
import { Footer } from "../Footer";
import upload from "../../images/upload.png";
import albumsetting from "../../images/albumsetting.png";
import songthumb from "../../images/songthumb.png";
import heart from "../../images/heart.png";
import dots from "../../images/dots.png";
import favourite from "../../images/favourite.png";
import songThrumb from "../../images/song-thumb.png";
import startcricle from "../../images/start_cricle.png";






var api_top = "https://staging.injn.io/secure/genres/popular";
var api_popular = "https://staging.injn.io/secure/genres/popular";

export class seeAll extends Component{ 
    constructor(props) {
        super(props);
        this.state={
            allGenres: [],
            popularGenres:[],
            isLoaded: false,
            allGenresdata:[],
            dataLoaded: false,
            songInfo: [],
            popularInfo: false,
            genresInfo: false,
            searchData:[],
            songDetails: '',
            searchfield: '',

        }
    }

    componentDidMount(){
        fetch(api_popular, {method:"GET"}).then(results =>{
          return results.json(); 
        }).then(data => {
            const popularGenres = data.slice(0,6).map((popgenres) => {
                return (
                    <div  className="text-center genere_inner" onClick={((e) => this.GetPopularAlbumDetail(e, popgenres))}  key={popgenres.id}>
                      < div className="first"  > < span >{popgenres.name}</span></div>
                  </div>            
              )
            })
            this.setState({ isLoaded: true, popularGenres: popularGenres });
        })
        
        
        fetch(api_top, { method: "GET" }).then(results => {
            return results.json();
        }).then(data => {
            console.log(data);
            const allGenres = data.map((genres) => {
                console.log(genres);
                return (
                    <div key={genres.id}>
                        <div className="col-xs-12 genres" >
                            <div className="gen_row">
                                <span className="col-xs-10">{genres.name}</span>
                                <a className="col-xs-2 text-right" href="javascript:void(0)"  onClick={((e) => this.GetAllAlbumDetail(e, genres))} ><img src={rightArrow} alt="" /></a>
                            </div>
                        </div></div>)
            })
            this.setState({ isLoaded: true, allGenres: allGenres,searchData:data});
        });
    }
    onSearchSongs(e, searchfield) {
        let isLoaded = true;
        if (e.target.value == "") {
            this.setState({ searchfield: e.target.value,searchDataLoaded:false })
        }
        else {
            this.setState({ searchfield: e.target.value,searchDataLoaded:true})
        }
     }  
    
    returnSeeAll() {
        let dataLoaded = false;
        this.setState({ dataLoaded: false, isLoaded: true });
    }

    GetPopularAlbumDetail(e, songInfo) {
        let isLoaded = true;
        console.log(songInfo);
        var songName=songInfo.name;
        var artist="artists"
        var GenresInfo=`https://staging.injn.io/secure/genres/${songName}/${artist}`
        console.log(GenresInfo);
        fetch(GenresInfo,{method: "GET"
        }).then(results => {
          return results.json();
        }).then(genresdata => {
          console.log(genresdata)
          let Genresdata=genresdata;
          this.setState({allGenresdata:Genresdata});
             let allGenresdata=<div className="album_detail_view" key={this.state.allGenresdata.genre.id}>
                        <div className="album_header">
                            <div className="row">
                                <div className="col-xs-8 text-left">
                                     <a href="javascript:void(0)" className="back_icon" onClick={((e) => {this.returnSeeAll()})}><img src={back} alt=""/></a>
                                </div>
                                {/* <div className="col-xs-4 text-right">
                                    <a href="#" className="header_upload"><img src={upload} alt=""/></a>
                                    <a href="#" className="header_setting"><img src={albumsetting} alt=""/></a>
                                </div> */}
                            </div>
                        </div>        
                        <div className="album_image Genre-image">
                            <img src="" alt=""/>
                            <p><span>{/*songInfo.length*//*songInfo.tracks.length*/} Songs</span>.<span>2019</span></p>
                        </div>
                        <div className="album_song_detail">
                            <div className="row">
                                <div className="col-xs-9">
                                    <h2 className="album_title">{this.state.allGenresdata.genre.name}</h2>
                            <p><span>{/*songInfo.artist.name*/}</span></p>
                                </div>
                                <div className="col-xs-3 text-right">
                                    <a linkto=""><img src={startcricle} alt="" /> </a>
                                </div>
                            </div>
                        </div>
                        <div className="album_song_list">
                            {this.state.allGenresdata.artistsPagination.data.map((relatedArtistdetails,index)=>
                                <div className="song-list row" key={relatedArtistdetails.id}>
                                    {/* <div className="col-xs-3">
                                            <img src={relatedArtistdetails.image_small} alt=""/>
                                        </div>  */}
                                    <div className="col-xs-12">
                                        <div className=" row_inner text-left">
                                            <h4>{relatedArtistdetails.name}</h4>
                                        </div>
                                    </div>
                                </div>

                                )}
                        </div>
                    </div>
            this.setState({ isLoaded: false, dataLoaded: true,songDetails:allGenresdata }) 
        });

       
    }

    GetAllAlbumDetail(e,songInfo) {
         let isLoaded = true;
        console.log(songInfo);
        let setData=<div key={songInfo.key} >
                        <div className="album_detail_view">
                        <div className="album_header">
                            <div className="row">
                                <div className="col-xs-8 text-left">
                            <a href="javascript:void(0)" className="back_icon" onClick={((e) => {this.returnSeeAll()})}><img src={back} alt=""/></a>
                                </div>
                                <div className="col-xs-4 text-right">
                                    {/* <a href="#" className="header_upload"><img src={upload} alt=""/></a>
                                    <a href="#" className="header_setting"><img src={albumsetting} alt=""/></a> */}
                                </div>
                            </div>
                        </div>        
                        <div className="album_image ">
                            <img src={songInfo.image} alt=""/>
                        </div>
                        <div className="album_song_detail">
                            <div className="row">
                                <div className="col-xs-9">
                                    <h2 className="album_title">{songInfo.name}</h2>
                            <p><span>{songInfo.artists}</span>.<span>{}{/* songInfo.tracks.length  */}Songs</span>.<span>2019</span></p>
                                </div>
                                <div className="col-xs-3 text-right">
                                    <a linkto=""><img src={startcricle} alt="" /> </a>
                                </div>
                            </div>
                            <div className="album_song_list">
                            {this.state.allGenresdata.artistsPagination.data.map((relatedArtistdetails,index)=>
                                <div className="song-list row" key={relatedArtistdetails.id}>
                                    {/* <div className="col-xs-3">
                                            <img src={relatedArtistdetails.image_small} alt=""/>
                                        </div>  */}
                                    <div className="col-xs-12">
                                        <div className=" row_inner text-left">
                                            <h4>{relatedArtistdetails.name}</h4>
                                        </div>
                                    </div>
                                </div>
                                
                                )}
                        </div>
                        </div>
                        {songInfo.tracks ?
                        <div className="album_song_list">
                            {songInfo.tracks.map(trackerInfo =>
                                <div key={trackerInfo.id}>
                                <div className="song-list row">
                                    <div className="col-xs-3">
                                        <img src={songInfo.image} alt=""/>
                                    </div>
                                    <div className="col-xs-9">
                                        <div className="row_inner">
                                        <div className="col-xs-8 text-left">
                                            <h4>{trackerInfo.name}</h4>
                                            <span>{}</span>
                                        </div>
                                        <div className="col-xs-4 text-right">
                                            <a href=""><img src={heart} alt=""/></a>
                                            <a href=""><img src={dots} alt=""/></a>
                                        </div>
                                    </div>
                                    </div>
                                    </div>
                                </div>
                             )} 
                            </div> :
                        ""}
                    </div></div>;
            this.setState({ isLoaded: false, dataLoaded: true,songDetails:setData }) 
    }

    render() {
        var { isLoaded, allGenres, popularGenres, dataLoaded, songDetails, searchfield, searchData, searchDataLoaded } = this.state;
        console.log(this.state.searchData);
         const filterCharacters = searchData.filter(SongsFiltered => {
                return SongsFiltered.name.indexOf(searchfield) > -1
            }) 
        return (
            <div className="seeall noshade">  
                {isLoaded ?
                    <div className="container">
                        <div className="row search_header">
                            <div className="col-xs-1">
                                <Link to="../Home" className="back_icon"><img src={back} alt="" /></Link>
                            </div>
                            <div className="col-xs-11">    
                                <div className="searchbar">
                                    <input className="search_input" type="text" name="" placeholder="Search..." onChange = {((e) => this.onSearchSongs(e,searchfield))}/>
                                    <a href="javascript:void(0)" className="search_icon"></a>
                                </div>
                            </div>
                        </div>
                        {searchDataLoaded ?
                            <div className="search-results">
                                    {filterCharacters.map(filteredresults =>
                                        <div key={filteredresults.id}>
                                            <div className="col-xs-12 genres" >
                                                <div className="gen_row">
                                                    <span className="col-xs-10">{filteredresults.name}</span>
                                                    <a className="col-xs-2 text-right" href="javascript:void(0)" onClick={((e) => this.GetAlbumTitle(e, filteredresults.id))}><img src={rightArrow} alt="" /></a>
                                                </div>
                                            </div>      
                                        </div>
                                    )} 
                            </div>
                            : ""}
                        <div className="popular_genres">
                            <div className="row">
                                <h3 className="col-xs-12 text-center">Popular Genres</h3>
                                {this.state.popularGenres}
                            </div>
                        </div>
                        <div className="all_genres">
                            <div className="row">
                                <h3 className="col-xs-12 text-center">All Genres</h3>
                                {this.state.allGenres}
                            </div>
                        </div>
                    </div>
                    : ""}
                    {dataLoaded ?
                    < div className = "container" >
                        {this.state.songDetails}
                    </div>
                    :""}
                    <Footer />
            </div>
        );
    }
}
      
