import React, {Component} from 'react';
import ReactDOM from 'react-dom';
//import Modal from 'react-modal';
import PropTypes from 'prop-types';
import $ from "jquery";
import {BrowserRouter,Route,browserHistory,Link} from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Carousel } from 'react-responsive-carousel';
import menuIcon from '../../images/home-menu-icon.png';
import settings from '../../images/settings.png';
import profilepic from '../../images/profile-pic.png';
import playalist from '../../images/my-playalist.png';
import location from '../../images/location.png';
import playlistimg from '../../images/playlist-img.png';
import  rightArrow from "../../images/right-arrow.png";
import {Header} from "../Header";
import {Footer} from "../Footer";
import heart from "../../images/heart.png";
import addFavourite from "../../images/favourite.png";
import dots from "../../images/dots.png";
import songThrumb from "../../images/song-thumb.png";
import followthumb from "../../images/follow-song-thumb.png";
import play from "../../images/play.png";


export class Userprofile extends Component{ 
    constructor(props) {
        super(props)
        this.state = {
            infoVal: false,
            Logininfo: false,
        }
    }	
    componentDidMount() {
        localStorage.getItem("Logininfo") && this.setState({
            Logininfo: localStorage.getItem("Logininfo"),
            infoVal: true
        })
    }
    render() {  
         var {infoVal,Logininfo,allInfo}=this.state;

         if (this.state.infoVal) {
             const Info = [Logininfo];
             const split = Info[0] == null ? "" : Info[0].split(",");
             allInfo = {
                 name: split[0],
                 img: split[1]
             }
        }
     
  return (
      
      <div className="Userprofile noshade">  
       <div className="cooming_soon">
                        <h3>Coming soon</h3>
                        <button>View Roadmap</button>
                     </div>
          <div className="container">

         
      
            {/* <div className="row userprofile_header">
                <div className="col-xs-6 text-left">
                    <Header/>
                </div>  
                <div className="col-xs-6 text-right">
                    <Link  to="./Usersetting" className="back_icon"><img src={settings} alt=""/></Link>
                </div>    
            </div>  
            <div className="profile-detail">
                  <div className="row">
                      {infoVal ?
                          <div className="col-xs-3">
                              <figure>
                                  <img src={allInfo.img} alt="" />
                              </figure>
                          </div> : <div className="col-xs-3">
                              <figure>
                                  <img src={profilepic} alt="" />
                              </figure>
                          </div>}
                    
                    <div className="col-xs-9">
                        <h3>{infoVal?allInfo.name : "Jason Ali"}</h3>
                        <span><img src={location} alt="" /> United Kingdom</span>
                    </div>
                </div>
            </div>
            <div className="profile-follow-info row">
                <div className="followers col-xs-4">
                    <span>564</span>
                    <label>Followers</label>
                </div>
                <div className="following col-xs-3">
                    <span>1,485</span>
                    <label>Following</label>
                </div>
                <div className="edit-Profile col-xs-5 text-right">
                   <Link to="./Editprofile"> <button>Edit Profile</button></Link>
                </div>
            </div>
            <div className="profile-page-tabs">
            <Tabs>
                <TabList className="nav nav-tabs">
                    <Tab className="text-center active">PLAYLISTS</Tab>
                    <Tab className="text-center">LIBRARY</Tab>
                    <Tab className="text-center">ACTIVITY</Tab>
                    <Tab className="text-center">Followers</Tab>
                    <Tab className="text-center">Following</Tab> 
                </TabList>
                      <div className="tab-content">
                               <TabPanel className="playlists tabs">
                        <div className="row profile-page-heding">
                            <div className="col-xs-9">
                                <h3><img src={playalist} />  My Playlists</h3>
                            </div>
                            <div className="col-xs-3 text-right">
                                <Link to="../home-content/seeAll">SEE ALL</Link>
                            </div>
                        </div>
                        <Carousel className="my-playlist row" infiniteLoop={true}  centerSlidePercentage={20} centerMode={false} showIndicators={false} showThumbs={false} swipeable={true} showArrows={false} showStatus={false}>
                          <div className="item " >
                            <div className="playlist-img">
                                <img src={playlistimg} alt="" />
                                <span>112 Songs</span>
                            </div>
                            <h3 className="playlist-name">My Running Playlist</h3>
                          </div>
                          <div className="item ">
                            <div className="playlist-img">
                                <img src={playlistimg} alt="" />
                                <span>112 Songs</span>
                            </div>
                            <h3 className="playlist-name">My Really Really Really Long Pla...</h3> 
                          </div>  
                          <div className="item ">
                                 <div className="playlist-img">
                                    <img src={playlistimg} alt="" />
                                    <span>112 Songs</span>
                                </div>
                                <h3 className="playlist-name">Playlist Name</h3>
                          </div>
                          <div className="item ">
                            <div className="playlist-img">
                                <img src={playlistimg} alt="" />
                                <span>112 Songs</span>
                            </div>
                            <h3 className="playlist-name">My Really Really Really Long Pla...</h3> 
                          </div>  
                          <div className="item ">
                            <div className="playlist-img">
                                <img src={playlistimg} alt="" />
                                <span>112 Songs</span>
                            </div>
                            <h3 className="playlist-name">Playlist Name</h3>
                          </div>
                          <div className="item ">
                             <div className="playlist-img">
                                <img src={playlistimg} alt="" />
                                <span>112 Songs</span>
                            </div>
                            <h3 className="playlist-name">My Really Really Really Long Pla...</h3> 
                          </div>  
                          <div className="item ">
                             <div className="playlist-img">
                                <img src={playlistimg} alt="" />
                                <span>112 Songs</span>
                            </div>
                            <h3 className="playlist-name">Playlist Name</h3>
                          </div>
                      </Carousel>
                      <Carousel className="my-playlist row" autoplay centerSlidePercentage={20} centerMode={true} showIndicators={false} showThumbs={false} swipeable={true} showArrows={false} showStatus={false} infiniteLoop={true} autoPlay={false} interval={3000}>
                          <div className="item " autoPlay>
                            <div className="playlist-img">
                                <img src={playlistimg} alt="" />
                                <span>112 Songs</span>
                            </div>
                            <h3 className="playlist-name">My Running Playlist</h3>
                          </div>
                          <div className="item ">
                            <div className="playlist-img">
                                <img src={playlistimg} alt="" />
                                <span>112 Songs</span>
                            </div>
                            <h3 className="playlist-name">My Really Really Really Long Pla...</h3> 
                          </div>  
                          <div className="item ">
                                 <div className="playlist-img">
                                    <img src={playlistimg} alt="" />
                                    <span>112 Songs</span>
                                </div>
                                <h3 className="playlist-name">Playlist Name</h3>
                          </div>
                          <div className="item ">
                            <div className="playlist-img">
                                <img src={playlistimg} alt="" />
                                <span>112 Songs</span>
                            </div>
                            <h3 className="playlist-name">My Really Really Really Long Pla...</h3> 
                          </div>  
                          <div className="item ">
                            <div className="playlist-img">
                                <img src={playlistimg} alt="" />
                                <span>112 Songs</span>
                            </div>
                            <h3 className="playlist-name">Playlist Name</h3>
                          </div>
                          <div className="item ">
                             <div className="playlist-img">
                                <img src={playlistimg} alt="" />
                                <span>112 Songs</span>
                            </div>
                            <h3 className="playlist-name">My Really Really Really Long Pla...</h3> 
                          </div>  
                          <div className="item ">
                             <div className="playlist-img">
                                <img src={playlistimg} alt="" />
                                <span>112 Songs</span>
                            </div>
                            <h3 className="playlist-name">Playlist Name</h3>
                          </div>
                      </Carousel>
                        <div className="row profile-page-heding">
                            <div className="col-xs-9">
                                <h3><img src={playalist} />Playlists I Follow</h3>
                            </div>
                            <div className="col-xs-3 text-right">
                                <Link to="../home-content/seeAll">SEE ALL</Link>
                            </div>
                        </div>  
                        <Carousel className="my-playlist row" autoplay centerSlidePercentage={20} centerMode={true} showIndicators={false} showThumbs={false} swipeable={true} showArrows={false} showStatus={false} infiniteLoop={true} autoPlay={false} interval={3000}>
                          <div className="item " autoPlay>
                            <div className="playlist-img">
                                <img src={playlistimg} alt="" />
                                <span>112 Songs</span>
                            </div>
                            <h3 className="playlist-name">My Running Playlist</h3>
                          </div>
                          <div className="item ">
                            <div className="playlist-img">
                                <img src={playlistimg} alt="" />
                                <span>112 Songs</span>
                            </div>
                            <h3 className="playlist-name">My Really Really Really Long Pla...</h3> 
                          </div>  
                          <div className="item ">
                                 <div className="playlist-img">
                                    <img src={playlistimg} alt="" />
                                    <span>112 Songs</span>
                                </div>
                                <h3 className="playlist-name">Playlist Name</h3>
                          </div>
                          <div className="item ">
                            <div className="playlist-img">
                                <img src={playlistimg} alt="" />
                                <span>112 Songs</span>
                            </div>
                            <h3 className="playlist-name">My Really Really Really Long Pla...</h3> 
                          </div>  
                          <div className="item ">
                            <div className="playlist-img">
                                <img src={playlistimg} alt="" />
                                <span>112 Songs</span>
                            </div>
                            <h3 className="playlist-name">Playlist Name</h3>
                          </div>
                          <div className="item ">
                             <div className="playlist-img">
                                <img src={playlistimg} alt="" />
                                <span>112 Songs</span>
                            </div>
                            <h3 className="playlist-name">My Really Really Really Long Pla...</h3> 
                          </div>  
                          <div className="item ">
                             <div className="playlist-img">
                                <img src={playlistimg} alt="" />
                                <span>112 Songs</span>
                            </div>
                            <h3 className="playlist-name">Playlist Name</h3>
                          </div>
                      </Carousel>
                      <Carousel className="my-playlist row" autoplay centerSlidePercentage={20} centerMode={true} showIndicators={false} showThumbs={false} swipeable={true} showArrows={false} showStatus={false} infiniteLoop={true} autoPlay={false} interval={3000}>
                          <div className="item " autoPlay>
                            <div className="playlist-img">
                                <img src={playlistimg} alt="" />
                                <span>112 Songs</span>
                            </div>
                            <h3 className="playlist-name">My Running Playlist</h3>
                          </div>
                          <div className="item ">
                            <div className="playlist-img">
                                <img src={playlistimg} alt="" />
                                <span>112 Songs</span>
                            </div>
                            <h3 className="playlist-name">My Really Really Really Long Pla...</h3> 
                          </div>  
                          <div className="item ">
                                 <div className="playlist-img">
                                    <img src={playlistimg} alt="" />
                                    <span>112 Songs</span>
                                </div>
                                <h3 className="playlist-name">Playlist Name</h3>
                          </div>
                          <div className="item ">
                            <div className="playlist-img">
                                <img src={playlistimg} alt="" />
                                <span>112 Songs</span>
                            </div>
                            <h3 className="playlist-name">My Really Really Really Long Pla...</h3> 
                          </div>  
                          <div className="item ">
                            <div className="playlist-img">
                                <img src={playlistimg} alt="" />
                                <span>112 Songs</span>
                            </div>
                            <h3 className="playlist-name">Playlist Name</h3>
                          </div>
                          <div className="item ">
                             <div className="playlist-img">
                                <img src={playlistimg} alt="" />
                                <span>112 Songs</span>
                            </div>
                            <h3 className="playlist-name">My Really Really Really Long Pla...</h3> 
                          </div>  
                          <div className="item ">
                             <div className="playlist-img">
                                <img src={playlistimg} alt="" />
                                <span>112 Songs</span>
                            </div>
                            <h3 className="playlist-name">Playlist Name</h3>
                          </div>
                      </Carousel>
                        <div className="row profile-page-heding">
                            <div className="col-xs-9">
                                <h3><img src={playalist} />My Favourites</h3>
                            </div>
                            <div className="col-xs-3 text-right">
                                <Link to="../home-content/seeAll">SEE ALL</Link>
                            </div>
                        </div>
                    </TabPanel>
                                <TabPanel className="library tabs">
                        <div className="row-inner">
                            <div className="col-xs-9 text-left">
                                <h3>songs</h3>
                            </div>
                            <div className="col-xs-3 text-right">
                                <Link to=""><img src={rightArrow} alt="" /> </Link>
                            </div>
                        </div>
                        <div className="row-inner">
                            <div className="col-xs-9 text-left">
                                <h3>albums</h3>
                            </div>
                            <div className="col-xs-3 text-right">
                                <Link to=""><img src={rightArrow} alt="" /> </Link>
                            </div>
                        </div>
                        <div className="row-inner">
                            <div className="col-xs-9 text-left">
                                <h3>Artists</h3>
                            </div>
                            <div className="col-xs-3 text-right">
                                <Link to=""><img src={rightArrow} alt="" /> </Link>
                            </div>
                        </div>
                        <div className="row-inner">
                            <div className="col-xs-9 text-left">
                                <h3>Genres</h3>
                            </div>
                            <div className="col-xs-3 text-right">
                                <Link to=""><img src={rightArrow} alt="" /> </Link>
                            </div>
                        </div>
                    </TabPanel>
                                <TabPanel className="activity tabs">
                    <div className="row profile-page-heding">
                            <div className="col-xs-9">
                                <h3><img src={play} />Recently Played</h3>
                            </div>
                            <div className="col-xs-3 text-right">
                                <Link to="javascript:void(0)">PLAY ALL</Link>
                            </div>
                        </div>
                    <div className="song-list row">
                        <div className="col-xs-3">
                            <img src={songThrumb} alt=""/>
                        </div>
                        <div className="col-xs-9">
                            <div className="row_inner">
                            <div className="col-xs-8 text-left">
                                <h4>Title</h4>
                                <span>Author</span>
                            </div>
                            <div className="col-xs-4 text-right">
                                <a href=""><img src={heart} alt="" /></a>
                                <a href=""><img src={dots} alt=""/></a>
                            </div>
                            </div>
                        </div>
                        </div>
                        <div className="song-list row">
                        <div className="col-xs-3">
                            <img src={songThrumb} alt=""/>
                        </div>
                        <div className="col-xs-9">
                            <div className="row_inner">
                            <div className="col-xs-8 text-left">
                                <h4>Title</h4>
                                <span>Author</span>
                            </div>
                            <div className="col-xs-4 text-right">
                                <a href=""><img src={heart} alt=""/></a>
                                <a href=""><img src={dots} alt=""/></a>
                            </div>
                            </div>
                        </div>
                        </div>
                        <div className="song-list row">
                        <div className="col-xs-3">
                            <img src={songThrumb} alt=""/>
                        </div>
                        <div className="col-xs-9">
                            <div className="row_inner">
                            <div className="col-xs-8 text-left">
                                <h4>Title</h4>
                                <span>Author</span>
                            </div>
                            <div className="col-xs-4 text-right">
                                <a href=""><img src={heart} alt=""/></a>
                                <a href=""><img src={dots} alt=""/></a>
                            </div>
                            </div>
                        </div>
                        </div>
                        <div className="song-list row">
                        <div className="col-xs-3">
                            <img src={songThrumb} alt=""/>
                        </div>
                        <div className="col-xs-9">
                            <div className="row_inner">
                            <div className="col-xs-8 text-left">
                                <h4>Title</h4>
                                <span>Author</span>
                            </div>
                            <div className="col-xs-4 text-right">
                                <a href=""><img src={heart} alt=""/></a>
                                <a href=""><img src={dots} alt=""/></a>
                            </div>
                            </div>
                        </div>
                        </div>
                    </TabPanel>
                                <TabPanel className="followers tabs">
                        <Tabs>
                            <div className="followers_tabs">
                                <TabList className="nav nav-tabs">
                                    <Tab className="text-center active">Users</Tab>
                                    <Tab className="text-center">Artists</Tab>
                                </TabList>
                            </div>    
                            <div className="tab-content">
                                <TabPanel className="user-tabs">
                                    <div className="row-inner">
                                        <div className="col-xs-3">
                                            <img src={followthumb} alt=""/>
                                        </div>
                                        <div className="col-xs-9">
                                            <div className="row">
                                                <div className="col-xs-6">
                                                    <h3>Title</h3>
                                                </div>
                                                <div className="col-xs-4 text-right">
                                                    <button>Follow</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row-inner">
                                        <div className="col-xs-3">
                                            <img src={followthumb} alt=""/>
                                        </div>
                                        <div className="col-xs-9">
                                            <div className="row">
                                                <div className="col-xs-6">
                                                    <h3>Title</h3>
                                                </div>
                                                <div className="col-xs-4 text-right">
                                                    <button>Follow</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row-inner">
                                        <div className="col-xs-3">
                                            <img src={followthumb} alt=""/>
                                        </div>
                                        <div className="col-xs-9">
                                            <div className="row">
                                                <div className="col-xs-6">
                                                    <h3>Title</h3>
                                                </div>
                                                <div className="col-xs-4 text-right">
                                                    <button>Follow</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row-inner">
                                        <div className="col-xs-3">
                                            <img src={followthumb} alt=""/>
                                        </div>
                                        <div className="col-xs-9">
                                            <div className="row">
                                                <div className="col-xs-6">
                                                    <h3>Title</h3>
                                                </div>
                                                <div className="col-xs-4 text-right">
                                                    <button>Follow</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </TabPanel>
                                <TabPanel>
                                <div className="row-inner">
                                        <div className="col-xs-3">
                                            <img src={followthumb} alt=""/>
                                        </div>
                                        <div className="col-xs-9">
                                            <div className="row">
                                                <div className="col-xs-6">
                                                    <h3>Title</h3>
                                                </div>
                                                <div className="col-xs-4 text-right">
                                                    <button>Follow</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row-inner">
                                        <div className="col-xs-3">
                                            <img src={followthumb} alt=""/>
                                        </div>
                                        <div className="col-xs-9">
                                            <div className="row">
                                                <div className="col-xs-6">
                                                    <h3>Title</h3>
                                                </div>
                                                <div className="col-xs-4 text-right">
                                                    <button>Follow</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row-inner">
                                        <div className="col-xs-3">
                                            <img src={followthumb} alt=""/>
                                        </div>
                                        <div className="col-xs-9">
                                            <div className="row">
                                                <div className="col-xs-6">
                                                    <h3>Title</h3>
                                                </div>
                                                <div className="col-xs-4 text-right">
                                                    <button>Follow</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row-inner">
                                        <div className="col-xs-3">
                                            <img src={followthumb} alt=""/>
                                        </div>
                                        <div className="col-xs-9">
                                            <div className="row">
                                                <div className="col-xs-6">
                                                    <h3>Title</h3>
                                                </div>
                                                <div className="col-xs-4 text-right">
                                                    <button>Follow</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </TabPanel>
                            </div>
                            
                        </Tabs>    
                    </TabPanel>
                                <TabPanel className="following tabs">
                    <Tabs>
                            <TabList className="nav nav-tabs">
                                <Tab className="text-center active">Users</Tab>
                                <Tab className="text-center">Artists</Tab>
                            </TabList>
                            <div className="tab-content">
                                <TabPanel className="user-tabs">
                                    <div className="row-inner">
                                        <div className="col-xs-3">
                                            <img src={followthumb} alt=""/>
                                        </div>
                                        <div className="col-xs-9">
                                            <div className="row">
                                                <div className="col-xs-6">
                                                    <h3>Title</h3>
                                                </div>
                                                <div className="col-xs-4 text-right">
                                                    <button>Following</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row-inner">
                                        <div className="col-xs-3">
                                            <img src={followthumb} alt=""/>
                                        </div>
                                        <div className="col-xs-9">
                                            <div className="row">
                                                <div className="col-xs-6">
                                                    <h3>Title</h3>
                                                </div>
                                                <div className="col-xs-4 text-right">
                                                    <button>Following</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row-inner">
                                        <div className="col-xs-3">
                                            <img src={followthumb} alt=""/>
                                        </div>
                                        <div className="col-xs-9">
                                            <div className="row">
                                                <div className="col-xs-6">
                                                    <h3>Title</h3>
                                                </div>
                                                <div className="col-xs-4 text-right">
                                                    <button>Following</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row-inner">
                                        <div className="col-xs-3">
                                            <img src={followthumb} alt=""/>
                                        </div>
                                        <div className="col-xs-9">
                                            <div className="row">
                                                <div className="col-xs-6">
                                                    <h3>Title</h3>
                                                </div>
                                                <div className="col-xs-4 text-right">
                                                    <button>Following</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </TabPanel>
                                <TabPanel>
                                <div className="row-inner">
                                        <div className="col-xs-3">
                                            <img src={followthumb} alt=""/>
                                        </div>
                                        <div className="col-xs-9">
                                            <div className="row">
                                                <div className="col-xs-6">
                                                    <h3>Title</h3>
                                                </div>
                                                <div className="col-xs-4 text-right">
                                                    <button>Following</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row-inner">
                                        <div className="col-xs-3">
                                            <img src={followthumb} alt=""/>
                                        </div>
                                        <div className="col-xs-9">
                                            <div className="row">
                                                <div className="col-xs-6">
                                                    <h3>Title</h3>
                                                </div>
                                                <div className="col-xs-4 text-right">
                                                    <button>Following</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row-inner">
                                        <div className="col-xs-3">
                                            <img src={followthumb} alt=""/>
                                        </div>
                                        <div className="col-xs-9">
                                            <div className="row">
                                                <div className="col-xs-6">
                                                    <h3>Title</h3>
                                                </div>
                                                <div className="col-xs-4 text-right">
                                                    <button>Following</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row-inner">
                                        <div className="col-xs-3">
                                            <img src={followthumb} alt=""/>
                                        </div>
                                        <div className="col-xs-9">
                                            <div className="row">
                                                <div className="col-xs-6">
                                                    <h3>Title</h3>
                                                </div>
                                                <div className="col-xs-4 text-right">
                                                    <button>Following</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </TabPanel>
                            </div>
                            
                        </Tabs>  
                    </TabPanel>
                </div>   
            </Tabs>
            </div> */}
        </div> 
       <Footer/>
    </div>
);
}
}