export default{
	GetDevice:{
		url:'https://api.spotify.com/v1/me/player/devices',
		method:'GET'
	},
		Player:{
		url:'https://api.spotify.com/v1/me/player',
		method:'GET'
	},
		CurrentlyPlaying:{
		url:'https://api.spotify.com/v1/me/player/currently-playing',
		method:'GET'
	},
		nextSong:{
		url:'https://api.spotify.com/v1/me/player/next',
		method:'POST'
	},
		previousSong:{
		url:'https://api.spotify.com/v1/me/player/previous',
		method:'POST'
	},
		PauseSong:{
		url:'https://api.spotify.com/v1/me/player/pause',
		method:'PUT'
	},
		PlaySong:{
		url:'https://api.spotify.com/v1/me/player/play',
		method:'PUT'
	},
		RepeatSong:{
		url:'https://api.spotify.com/v1/me/player/repeat',
		method:'PUT'
	},
		Seek:{
		url:'https://api.spotify.com/v1/me/player/seek',
		method:'PUT'
	},
		ShuffleSong:{
		url:'https://api.spotify.com/v1/me/player/shuffle',
		method:'PUT'
	},Volume:{
		url:'https://api.spotify.com/v1/me/player/volume',
		method:'PUT'
	},
 	apiNewreleases:{
 		url:'https://staging.injn.io/secure/albums/new-releases',
 		method:'GET'
 	},
	popularGenres:{
		url:'https://staging.injn.io/secure/genres/popular',
		method:"GET"
	},
	TrendingTacksAPI : {
		url:'https://staging.injn.io/secure/tracks/top',
		method:"GET"
	},
	isFeaturedApi : {
		url:'https://staging.injn.io/secure/tracks/top',
		method:"GET"
	},
	newSinglesApi : {
		url:"https://staging.injn.io/secure/tracks",
		method:"GET"
	},
	 AlbumsApi : {
	 	url:"https://staging.injn.io/secure/albums",
	 	method:"GET"
	 },
	 menuIcon :{
	 	url:'../images/menu-icon.png'
	 },
	downArrow :{
	 	url:'../images/dropdown-arrow-01.png'
	},
	upArrow :{
		url:'../images/upArrow.png'
	},
	menuClose :{
	 	url:'../images/menu-close.png'
	},
	logo :{
	 	url:'../images/logo.png'
	},
	homeIcon :{
	 	url:"../images/home-menu-icon.png"
	},
	notification :{
		url:"../images/notification.png"
	},
	songThrumb :{
		url:"../images/song-thumb.png"
	},
	newRelease :{
		url:"../images/newrelease.png"
	},
	music :{
		url:"../images/music.png"
	},
	options :{
		url:"../images/options.png"
	},
	heart :{
		url:"../images/heart.png"
	},
	addFavourite :{
		url:"../images/favourite.png"
	},
	dots :{
		url:"../images/dots.png"
	},
	favourite :{
		url:"../images/favourite.png"
	},
	backward :{
		url:"../images/backward.png"
	},
	start :{
		url:"../images/start.png"
	},
	push :{
		url:"../images/push.png"
	},
	playing :{
		url:"../images/playing.png"
	},
	forward :{
		url:"../images/forward.png"
	},
	playSetting :{
		url:"../images/play-setting.png"
	},
	openshuffel :{
		url:"../images/open-shuffel.png"
	},
	star :{
		url:"../images/fire-trending.png"
	},
	Indicator :{
		url:"../images/Disclosure_Indicator.png"
	},
	loading :{
		url:"../images/loading.gif"
	},
	downarrow :{
		url:"../images/pink-downarrow.png"
	},
	raisingboth :{
		url:"../images/raising-both.png"
	},
	playlistimg :{
		url:'../images/playlist-img.png'
	},
	index :{
		url:'../images/index.jpg'
	},
	index2 :{
		url:'../images/index2.jpg'
	},
	index3 :{
		url:'../images/index3.jpeg'
	},
	index4 :{
		url:'../images/index4.jpg'
	},
	back :{
		url:"../images/back.png"
	},
	startcricle :{
		url:"../images/start_cricle.png"
	},

}

