import React, { Component } from 'react';
import {BrowserRouter,MemoryRouter,Route,Link,} from 'react-router-dom';
import './css/style.css';
import {Header} from "./components/Header";
import Home from "./components/Home";
import {splash} from "./components/splash";
import {Aboutus} from "./components/Aboutus";
import {WorkWithus} from "./components/Workwithus";
import {ContentSection} from "./components/ContentSection";
import {seeAll} from "./components/home-content/seeAll";
import {Userprofile} from "./components/home-content/Userprofile";
import {Search} from "./components/home-content/Search";
import {Newplaylist} from "./components/home-content/Newplaylist";
import {Usersetting} from "./components/home-content/Usersetting";
import {Editprofile} from "./components/home-content/Editprofile";
import InitPlayer from "./components/spotifyPlayer/InitPlayer";
import responsiveMusicPlayer from "./components/responsiveMusicPlayer";
import {Footer} from "./components/Footer";
import $ from 'jquery';
import './bootstrap/css/bootstrap.min.css';
import './fonts/sanfrancisco-font.css';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import TestSpotify from './components/spotifyPlayer/TestSpotify';

//console.log = function () {}

class App extends Component { 
  constructor(props){
    super(props)
      this.state={
          playerStatus:localStorage.getItem('loginStatus') 
      }
  }
  render() {
    return (
      <div className="App">
      <BrowserRouter>
          <div className="routs">
            <Route  path="/" exact component={Home} />
            <Route path="/Home" component={Home} />
            <Route path="/Aboutus" component={Aboutus} />
            <Route path="/Workwithus" component={WorkWithus} />
            <Route path="/home-content/seeAll" component={seeAll} />
            <Route path="/home-content/Search" component={Search} />
            <Route path="/home-content/Newplaylist" component={Newplaylist} />
            <Route path="/home-content/Userprofile" component={Userprofile} />
            <Route path="/home-content/Usersetting" component={Usersetting} />
            <Route path="/home-content/Editprofile" component={Editprofile} />
            <Route path="/spotifyPlayer/InitPlayer" component={InitPlayer} />
            <Route path="/responsiveMusicPlayer" component={responsiveMusicPlayer} />
          </div>
        </BrowserRouter>
       {this.state.playerStatus && <TestSpotify/>}
      </div>
    );
  }
}
export default App;
