import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {GoogleLogout} from 'react-google-login';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import $ from 'jquery';
import logo from '../images/logo.png';
import menuIcon from '../images/menu-icon.png';
import menuClose from '../images/menu-close.png';
import homeIcon from "../images/hamburger-menu-icon.svg";
import notification from "../images/notification.png";
import NavLink from 'react-bootstrap/NavLink';
import Palette from 'react-palette';

export class Header extends Component{
     constructor(props) {
         super(props);
         this.state = {
            activeClass:'',
             addClass: false,
             removeClass: false,
             home: false,
             otherpage:false,
             href:window.location.href.substr(window.location.href.lastIndexOf('/')+1),
             menucolor: false,
             LocalStorage: false,
             imageurl: this.props.imgurl,
             date:this.props.date
         }

     }


    componentDidMount() {
        var headerHeight=$(".header").outerHeight();
        window.addEventListener('scroll', (event) => {
            if($(window).scrollTop() > headerHeight){
                this.setState({
                    activeClass: true
                 })
            }else{
                this.setState({
                    activeClass: false
                 })
            }
            
         });
        if (this.state.href == "Home" || this.state.href == '') {
            this.setState({ home: true, otherpage: false })
         
        }
        else {
            this.setState({ home: false, otherpage: true })
        }

        $(".menu-item a").each(function(i,element){
            var getActiveelem=$(this).attr("href").replace("/","");
            var href= window.location.href.substr(window.location.href.lastIndexOf('/') + 1);
            if(href===getActiveelem){
                $(this).parent().siblings().removeClass("active");
                $(this).parent().addClass("active");
            }
        })
        if($("header").hasClass("white")){
           this.setState({menucolor:false});
       }else{ 
            this.setState({menucolor:true});
        }       
    }
    logout() {
        this.setState({LocalStorage:localStorage.clear()})
          }

     toggle() {
         this.setState({
             addClass: !this.state.addClass
         });
         this.setState({
             removeClass: this.state.removeClass
         })
    }
    


     render(){
     let menuToggleOpen = ["sidenav"];
     let menuToggleClose = ["sidenav"];
     if (this.state.addClass) {
         menuToggleOpen.push('menu-toggel');
     } else if (this.state.addClass) {
         menuToggleClose.removeClassName("menu-toggel");
         }
return (
    <div>
    <Palette image={this.state.imageurl}>
            {palette => (
                <div className="shadow" style={{ backgroundColor: palette.vibrant }}></div>
            )}      
    </Palette>
    <div className={this.state.activeClass ? "header top": "header normal"}>
    <div className = "menu">
    {this.state.home ?   
        <div className="container">
            <div className="row">
            <div className="menu col-xs-2">
               {/*  <select className="exactDate"><option>{this.props.month + "-" +this.props.year}</option></select> */} 
                <div id="mySidenav" className={menuToggleOpen.join(' ')} >
                    <div className="menu-overlay">
                        <a href="javascript:void(0)" className="closebtn" onClick={this.toggle.bind(this)}><img src={menuClose} alt="close-icon"/></a>
                        <ul className="menu-item">
                            <li className="brand"><a href=""><img src={logo}/></a></li>     
                            <li><Link to="/Home">Home</Link></li> 
                            <li><Link to="/">Contact</Link></li> 
                            <li><Link to="/Aboutus">About us</Link></li> 
                            <li><Link to="/Workwithus">Work With Us</Link></li> 
                            <li><Link to="/">Help & Support</Link></li> 
                            <li><Link to="/">What’s New</Link></li> 
                            <li >  <button className="logout-btn" onClick={((e)=>this.logout(e))}>Log out</button></li>
                        </ul>
                        <Link to="/" className="menu-icon" onClick={this.toggle.bind(this)}><img src={homeIcon}/> </Link>
                        </div>
                </div>
            </div>
            <div className="release col-xs-6 text-left">
                <h2>New Releases</h2>
            </div>
            <div className="new-cal">
           
            {/* <DatePicker selected={this.state.startDate} onChange={this.handleChange} dateFormatCalendar={"MMM yyyy"} minDate={subMonths(new Date(), 6)} maxDate={addMonths(new Date(), 6)} showMonthYearDropdown/> */}

                                
            </div>
            </div>
        </div>
 
 :< div id="mySidenav"  className={menuToggleOpen.join(' ')}  > 
    <div className="menu-overlay">
    < a href="javascript:void(0)"
        className="closebtn" onClick={this.toggle.bind(this)} > < img src={menuClose}
            alt="menu-close" /> </a><ul className="menu-item"><li className="brand">
                <Link to="/Home">
                    < img src={logo}
                        alt="logo" /> </Link></li >
        < li > < Link to="/Home" > Home </Link></li >
        <li><Link to="/Aboutus" > About us </Link></li >
        <li><Link to="/" > Contact </Link></li >
        < li > <Link to="/Workwithus"> Work with us </Link></li >
        <li><Link to="/" > Help & Support </Link></li >
        <li><Link to="/" > What’ s New </Link></li>
        <li ><button className="logout-btn" onClick={((e)=>this.logout(e))}>Log out</button>  {/* <GoogleLogout   client_id="976477062683-o43hr3t5836iaj8t4tsb9mcefsnv7aps.apps.googleusercontent.com" render={renderProps => (<button >Log out</button>)} buttonText="Login out" onLogoutSuccess={logout}></GoogleLogout> */}</li>
    </ul><p className="menu-icon" onClick={this.toggle.bind(this)}>
        <img src={this.state.menucolor ?homeIcon: menuIcon} /></p>
        </div> 
</div>
}
</div>
</div>
</div>    
);

}
}
//export default MusicPlayer(header);