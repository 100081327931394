import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import searchbar from "../images/search.png";
import usericon from '../images/user.png';
import homeicon from '../images/Home.png';
import homebg from '../images/homebg.png';
import userbg from '../images/userbg.png';
import searchbg from '../images/searchbg.png';
import Home from "./Home";

export class Footer extends Component{
  constructor(props) {
    super(props)
    this.state={
      addClass1: false,
      addClass2: false,
      addClass3: false,
    }
    
  }
  componentWillMount() {
    const href = window.location.href.substr(window.location.href.lastIndexOf('/') + 1);
  if (href == "Home" || href == "home" || href=="") {
        this.setState({ addClass1: true ,addClass2:false,addClass3:false });
      }
    if (href ==="Search") {
     this.setState({addClass1: false, addClass2: true, addClass3: false });
    }
    if (href == "Users" || href == "Userprofile") {
    this.setState({ addClass1: false, addClass2:false,  addClass3: true });
    } 
  }
  
  
  
  render() {
    return (<footer>
      <div className="container">
        <div className="row">
          <div className={this.state.addClass1 ? "col-xs-4 text-center home active " : "col-xs-4 text-center home"}>
            <Link to="/Home"><img src={homeicon} /></Link>
            <img className="homebg" src={homebg} />
          </div>
          <div className={this.state.addClass2 ? "col-xs-4 text-center search active " : "col-xs-4 text-center search"}>
            <Link to="../home-content/Search"></Link>
            <img className="searchbg" src={searchbg} />
          </div>
        {this.props.data ?<div className={this.state.addClass3 ? "col-xs-4 text-center user active activepic" : "col-xs-4 text-center user activepic"}>
              <Link to="../home-content/Userprofile"><img src={ this.props.data[1]} /></Link>
            </div> : <div className={this.state.addClass3 ? "col-xs-4 text-center user active" : "col-xs-4 text-center user"} >
              <Link to="../home-content/Userprofile"><img src={usericon} /></Link>
              <img className="userbg" src={userbg} />
            </div>}

        </div>
      </div>
    </footer>)
  }

}
