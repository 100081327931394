import React, {Component} from 'react';
import { Link, Redirect } from "react-router-dom";
import Apis from '../Apis';
import {isFeaturedSettings} from '../../constants/sliderSetting';
import {month,shortMonth} from '../../constants/TimeStamp';
import Slider from "react-slick";

class NewReleasesGeneres extends Component{
	constructor(props){
		super(props);
		this.state={
			isFeatured: [],
			artistBkImage:null
		}

	}

	componentDidMount(){
		 fetch(Apis.isFeaturedApi.url, {
            method: Apis.isFeaturedApi.method
        }).then(results => {
            return results.json()
        }).then(response => {
            this.setState({ isFeatured: response });
            this.featuredReleaseByFilter();
        });
	}

	featuredReleaseByFilter = (currentMonth = new Date().getMonth() + 1, currentYear = new Date().getFullYear()) => {
        var featuredData = this.state.isFeatured;
        var selectedDate = month[currentMonth] + currentYear;
        if (featuredData.length) {
            var renderFeatured = featuredData.filter(function(value, index, array) {
                if (value.album.release_date !== null) {
                    var trackDate = month[new Date(value.album.release_date).getMonth() + 1] + new Date(value.album.release_date).getFullYear();
                    if (selectedDate === trackDate && value.is_featured === "true") {
                        return value;
                    }
                }
                return;
            });
            this.setState({ isFeatured: renderFeatured });
        } else {
            this.setState({ isFeatured: featuredData });
        }
    }

    getAritstImage=(e) =>{
        var artistId = document.querySelector(".singel .slick-active #artist-id").val();

        fetch(`https://staging.injn.io/secure/artists/${artistId}?with=similar,genres&top_tracks=true`, { method: "GET" }).then(results => {
            return results.json()
        }).then(getFeaturedBkImage => {
            var getArtistImage = getFeaturedBkImage.artist.image_large;
            this.setState({ artistBkImage: getArtistImage });
        })
    }


render(){
	return(  <div className="artist_slider">
	                  <Slider {...isFeaturedSettings} className="singel" onSwipe={((e)=>this.getAritstImage(e))}>
	                    {this.state.isFeatured.map(filtereddata =>
	                      <div className="item" key={filtereddata.id} onClick={this.handleShowhome}>
	                      <img className="artist-bgimage" src={this.state.artistBkImage} alt=""/>  
	                        <div className="row">
	                          <div className="col-xs-3">
	                            <img src={""} alt="" />
	                          </div>
	                          <div className="col-xs-9 text-left">
	                            <input type="text" id="artist-id" value={""}/>
	                            <label className="artist-name">{}</label><br />
	                                
	                             {/*<span className="song-name">{filtereddata.name}/{filtereddata.album_name}/EP TITLE</span> */}
	                            <p className="new-ep text-right"><span>New EP</span> </p>
	                          </div>
	                        </div>
	                      </div>

	                    )}
	                  </Slider>
	                </div>)

	}
}

export default NewReleasesGeneres;